import { type ReactNode, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../../components/global/amp/Footer";
import { useAppSelector } from "../../../store";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { apiSlice } from "../../../store/apiSlice";
import {
  canViewAttraction,
  isAttractionDenied,
  isAttractionOnlyNew,
  isAttractionPending,
} from "./Dashboard";
import { PrimaryButton } from "../../../components/global/PrimaryButton";
import { ProgressBar } from "../attractions/AttractionVenueOptScore";
import { VenueOptScoreProgressCircular } from "../../../components/amp/VenueOptScoreProgressCircular";

const features = [
  {
    title: "Increased Visibility",
    content:
      "Higher scores result in better positioning and more prominence on the DOWTK website.",
  },
  {
    title: "Enhanced Trust",
    content:
      "Verified and well-maintained listings build confidence among visitors.",
  },
  {
    title: "Data-Driven Improvements",
    content: "Access to insights that guide effective enhancements.",
  },
  {
    title: "Competitive Edge",
    content:
      "Stand-out in a crowded market with a high-quality, optimised listing. We’ll give you all the tips you need to achieve maximum returns.",
  },
];

const metrics = [
  {
    title: "Verification Status",
    content:
      "Ensuring your listing is claimed and verified by the venue owner via the Connect Platform",
  },
  {
    title: "Visual Appeal",
    content: "Number, uniqueness, and quality of images on your listing.",
  },
  {
    title: "Content Quality",
    content:
      "The uniqueness, readability and word count of the venue written content.",
  },
  {
    title: "Customer Feedback",
    content: "Overall rating from reviews, including volume and recency.",
  },
  {
    title: "Accuracy and Transparency",
    content:
      "Up-to-date information on prices, opening times and seasonal changes",
  },
  {
    title: "Booking Options",
    content: "Availability of ticket booking directly through DOWTK.",
  },
];

export default function VenueOptScore() {
  useEffect(() => {
    document.title = "Venue Optimisation Score | Day Out With The Kids";
  }, []);

  const { user } = useAppSelector((state) => state.global);
  const { data: allAttractions } = apiSlice.useListAttractionsQuery({
    all: true,
  });
  const [searchParams] = useSearchParams();
  const attractions = allAttractions?.results?.filter((r) =>
    canViewAttraction({
      pending: isAttractionPending(r),
      denied: isAttractionDenied(r),
      onlyNew: isAttractionOnlyNew(r),
    })
  );

    const {
      data: fullAttraction,
    } = apiSlice.useGetAttractionQuery(searchParams.get("short_id") ?? "");
  const firstAttraction =
    attractions?.find(
      (attraction) => attraction.short_id === searchParams.get("short_id")
    ) || attractions?.[0];

  const { data: getMetrics } = apiSlice.useGetVenueOptScoreMetricsQuery();
  const currentAttraction = fullAttraction?.result || firstAttraction;
  const attractionScores = (fullAttraction?.result?.published_version || firstAttraction)?.optimisation_scores;
  const completedTasks = getMetrics?.result
    ?.filter(
      (metric) =>
        !!attractionScores?.find((s) => s.metric_key === metric.key)?.fulfilled
    )
    .map((s) => ({ ...s, completed: true }));
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role === 'ADMIN' && searchParams.get("short_id")) {
      navigate(`/attractions/${searchParams.get("short_id")}/venue-optimisation-score`);
    }
  }, [user?.role, navigate, searchParams]);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <DashboardHeaderContainer slim={true}>
        <div className="flex items-center flex-wrap gap-y-2 w-[calc(100%-28px)] xl:w-full">
          <h1 className="font-extrabold text-xl text-black">Dashboard</h1>
        </div>
      </DashboardHeaderContainer>

      <Container>
        <h2 className="text-[22px] font-extrabold text-[#212121]">
          ⚡️ Venue Optimisation Score (VO Score)
        </h2>

        <p className="pl-6 font-normal text-sm text-[#212121] mt-5 leading-[21px] w-2/3">
          Elevate your attraction's profile with the brand new Venue
          Optimisation Score! Harness the power of ‘VOS’ to boost your online
          presence, be seen by more of our 35 million annual visitors, and turn
          clicks into crowds. Every attraction has a score out of 100. Get ahead
          in the UK's bustling family days out scene – Enhance, engage, excel
          with ‘VOS’.
        </p>
      </Container>
      {currentAttraction && (
        <Container>
          <div className="w-full md:w-1/2">
            <div className="flex gap-5 items-center">
              <VenueOptScoreProgressCircular
                score={currentAttraction?.published_version?.total_vo_score || 0}
                maxScore={currentAttraction?.published_version?.max_possible_vo_score || 100}
                size={85}
                stroke={9}
              >
                <div className="flex flex-col items-center text-lg font-bold">
                  <div>{currentAttraction?.published_version?.total_vo_score || 0}</div>
                  <div className="h-[1px] bg-black w-8"></div>
                  <div>{currentAttraction?.published_version?.max_possible_vo_score || 100}</div>
                </div>
              </VenueOptScoreProgressCircular>

              <h1 className="text-[22px] font-bold">{currentAttraction?.name}</h1>
            </div>

            <div className="mt-5 flex items-center gap-[5px]">
              <div className="text-[22px] font-bold">
                {completedTasks?.length || 0}/{getMetrics?.result?.length || 0}
              </div>
              <div className="text-sm font-normal">Optimisations Completed</div>
            </div>

            <div className="mt-5">
              <ProgressBar
                percent={
                  ((completedTasks?.length || 0) * 100) /
                  (getMetrics?.result?.length || 1)
                }
              />
            </div>

            <p className="font-normal text-sm text-[#5F646D] mt-5 leading-[21px]">
              The more optimisations you complete, the higher your VO Score,
              which will lead to more prominence and exposure across the DOWTK
              website.
            </p>
          </div>
          {currentAttraction && (
              <div className="mt-5 flex">
                <PrimaryButton
                  scale="sm"
                  type="button"
                  className={`px-4 ml-auto`}
                  onClick={() =>
                    navigate(
                      `/attractions/${currentAttraction.short_id}/subscription`
                    )
                  }
                >
                  Upgrade to Unlock Optimisations
                </PrimaryButton>
              </div>
            )}
        </Container>
      )}
      <Container>
        <div className="pl-6 flex flex-col gap-y-2.5 w-2/3">
          <h2 className="font-extrabold text-base text-[#212121]">BENEFITS</h2>

          {features.map((f) => (
            <p className="font-normal text-sm text-[#212121] leading-[21px]">
              <span className="font-bold">{f.title}:</span> {f.content}
            </p>
          ))}
        </div>
      </Container>
      <Container>
        <div className="pl-6 flex flex-col gap-y-2.5 w-2/3">
          <h2 className="font-extrabold text-base text-[#212121] mb-2.5">
            KEY METRICS
          </h2>

          {metrics.map((f) => (
            <p className="font-normal text-sm text-[#212121] leading-[21px]">
              <span className="font-bold">
                <ThunderIcon /> {f.title}:
              </span>{" "}
              <span>{f.content}</span>
            </p>
          ))}
        </div>

        {firstAttraction && (
          <div className="mt-5 flex">
            <PrimaryButton
              scale="sm"
              type="button"
              className={`px-4 ml-auto`}
              onClick={() =>
                navigate(
                  `/attractions/${firstAttraction.short_id}/subscription`
                )
              }
            >
              Upgrade to Unlock Optimisations
            </PrimaryButton>
          </div>
        )}
      </Container>

      <div className="mb-10"></div>

      <Footer />
    </div>
  );
}

function ThunderIcon() {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-1 inline"
    >
      <path
        d="M11.7298 7.32865C11.7017 7.20854 11.6444 7.09721 11.563 7.00454C11.4815 6.91187 11.3785 6.84073 11.263 6.7974L7.86047 5.52178L8.73547 1.14678C8.76746 0.98677 8.74639 0.820694 8.67546 0.673742C8.60453 0.526789 8.48761 0.406978 8.34243 0.332482C8.19725 0.257985 8.03174 0.232867 7.871 0.260938C7.71026 0.289009 7.56306 0.368737 7.45172 0.488029L0.451721 7.98803C0.367502 8.07817 0.30682 8.1877 0.275051 8.3069C0.243283 8.4261 0.241408 8.5513 0.269595 8.6714C0.297781 8.7915 0.355157 8.9028 0.436639 8.99542C0.518121 9.08804 0.621193 9.15914 0.736721 9.2024L4.13922 10.478L3.26422 14.853C3.23223 15.013 3.2533 15.1791 3.32423 15.3261C3.39516 15.473 3.51208 15.5928 3.65726 15.6673C3.80244 15.7418 3.96795 15.7669 4.12869 15.7389C4.28943 15.7108 4.43664 15.6311 4.54797 15.5118L11.548 8.01178C11.6321 7.92164 11.6927 7.81214 11.7244 7.69299C11.7562 7.57383 11.758 7.4487 11.7298 7.32865ZM5.25297 12.558L5.73547 10.1468C5.77037 9.97216 5.742 9.79081 5.65543 9.63519C5.56886 9.47958 5.42974 9.35984 5.26297 9.2974L2.31235 8.19178L6.74547 3.44178L6.26297 5.85303C6.22807 6.02765 6.25644 6.20899 6.34301 6.36461C6.42958 6.52023 6.5687 6.63997 6.73547 6.7024L9.68735 7.80803L5.25297 12.558Z"
        fill="#212121"
      />
    </svg>
  );
}

function Container({ children }: { children: ReactNode }) {
  return (
    <DashboardContainer>
      <section
        className={`flex flex-col  xl:mx-[70px] p-5 bg-white xl:rounded-lg`}
      >
        {children}
      </section>
    </DashboardContainer>
  );
}
