import {
  ChartData,
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";

interface IDashboardChart {
  data: DataPoint[];
  secondaryData: DataPoint[];
  activePeriod: number;
}

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Legend,
  Filler
);

const DashboardChart: React.FC<IDashboardChart> = ({
  data,
  secondaryData,
  activePeriod,
}) => {
  const labels = useMemo(
    () => data.reduce((prev, curr, i) => [...prev, curr.label], [] as string[]),
    [data]
  );
  const mainLabels = useMemo(
    () => data.reduce((prev, curr, i) => [...prev, formateDate(curr.key, activePeriod === 3)], [] as string[]),
    [data, activePeriod]
  );
  const values = useMemo(
    () => data.reduce((prev, curr, i) => [...prev, curr.value], [] as any[]),
    [data]
  );

  const shortLabels = labels.map((label) => {
    let words = label.split(" "); // Split the sentence into an array of words
    let word = words[0]; // Get the first word from the array
    let result =
      word.substring(0, 3) + " " + (activePeriod === 2 ? words[1] : ""); // Extract the first three letters

    return result;
  });

  const secondaryLabels = useMemo(
    () =>
      secondaryData.reduce(
        (prev, curr, i) => [...prev, formateDate(curr.key, activePeriod === 3)],
        [] as string[]
      ),
    [secondaryData, activePeriod]
  );
  const secondaryValues = useMemo(
    () =>
      secondaryData.reduce(
        (prev, curr, i) => [...prev, curr.value],
        [] as any[]
      ),
    [secondaryData]
  );

  const shortSecondaryLabels = secondaryLabels.map((label) => {
    return label;
  });

  const sumValues = values.reduce(function (a, b) {
    return a + b;
  }, 0);
  const sumSecondaryValues = secondaryValues.reduce(function (a, b) {
    return a + b;
  }, 0);

  const [getCurrentVisibility, setCurrentVisibility] = useState<boolean>(false);
  const [getPreviousVisibility, setPreviousVisibility] =
    useState<boolean>(false);

    const chartData = useMemo<ChartData<'line'>>(() => ({
      labels: [mainLabels, shortSecondaryLabels],
      datasets: [
        {
          label: ``,
          data: values,
          borderColor: "#0052FF",
          backgroundColor: (context) => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 300);
            gradient.addColorStop(0, "rgba(0,82,255, 0.3)");
            gradient.addColorStop(1, "rgba(0,82,255, 0)");
            return gradient;
          },
          tension: 0,
          hidden: getCurrentVisibility,
          fill: true,
          pointHoverRadius: 7,
          pointHoverBackgroundColor: "#0052FF",
          pointHoverBorderColor: "#D6DFFD",
          pointHoverBorderWidth: 3,
          pointBorderWidth: 0,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
        },
        {
          label: ``,
          data: secondaryValues,
          borderColor: "#81DE4A",
          backgroundColor: (context) => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 300);
            gradient.addColorStop(0, "rgba(129,222,74, 0.3)");
            gradient.addColorStop(1, "rgba(129,222,74, 0)");
            return gradient;
          },
          tension: 0,
          hidden: getPreviousVisibility,
          fill: true,
          pointHoverRadius: 7,
          pointHoverBackgroundColor: "#81DE4A",
          pointHoverBorderColor: "#DEF4CB",
          pointHoverBorderWidth: 3,
          pointBorderWidth: 0,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
        },
      ],
    }), [mainLabels, values, secondaryValues, getCurrentVisibility, getPreviousVisibility]);
  const toggleData: any = (value: any) => {
    const visibilityData = chartData.datasets[value].hidden;

    if (value === 0) {
      setCurrentVisibility(visibilityData ? false : true);
    } else {
      setPreviousVisibility(visibilityData ? false : true);
    }
  };

  return (
    <>
      <div className="flex items-center gap-x-5 my-3">
        <button
          className={`flex gap-[6px] border border-[#CFDBD5] h-[26px] py-[5px] px-3 rounded-full items-center ${
            getCurrentVisibility && "opacity-50"
          }`}
          onClick={() => toggleData(0)}
        >
          <span className={`bg-[#0052FF] w-[9px] h-[9px] rounded-full`}></span>
          <div className="flex gap-1 items-center text-left text-xs font-bold text-black font-['Inter_var']">
            <span>{sumValues.toLocaleString()}</span>-
            <span>
              Last{" "}
              {activePeriod === 1
                ? "7 days"
                : activePeriod === 2
                ? "30 days"
                : "12 months"}
            </span>
          </div>
        </button>
        <button
          className={`flex gap-[6px] border border-[#CFDBD5] h-[26px] py-[5px] px-3 rounded-full items-center ${
            getPreviousVisibility && "opacity-50"
          }`}
          onClick={() => toggleData(1)}
        >
          <span className={`bg-[#81DE4A] w-[9px] h-[9px] rounded-full`}></span>
          <div className="flex gap-1 items-center text-left text-xs font-bold text-black font-['Inter_var']">
            <span>{sumSecondaryValues.toLocaleString()}</span>-
            <span>
              Previous{" "}
              {activePeriod === 1
                ? "7 days"
                : activePeriod === 2
                ? "30 days"
                : "12 months"}
            </span>
          </div>
        </button>
      </div>
      <div className="relative h-full max-w-[calc(100vw-500px)]">
        <Line
          data={chartData}
          options={{
            animation: {
              duration: 300,
            },
            onHover: (event, activeElements) => {
              (event?.native?.target as HTMLElement).style.cursor =
                activeElements?.length > 0 ? "none" : "auto";
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                labels: shortLabels,
                grid: {
                  display: false,
                },
                ticks: {
                  callback: function (context) {
                    return this.getLabelForValue(Number(context));
                  },
                  font: {
                    size: 14,
                  },
                  color: "black",
                },
                border: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  stepSize: activePeriod !== 3 ? 50 : 500,
                  callback: function (value, index) {
                    if (value !== 0) {
                      if (typeof value === "string") {
                        return value;
                      }
                      return value.toLocaleString();
                    }
                  },
                  color: "black",
                  font: {
                    size: 12,
                  },
                },
                grid: {
                  display: true,
                  color: "#CFDBD5",
                },
                border: {
                  display: false,
                  dash: [2, 4],
                },
              },
            },
            plugins: {
              legend: {
                display: false,

                labels: {
                  color: "#555",
                  font: {
                    family: "Montserrat",
                    size: 13,
                  },
                  boxHeight: 0,
                  padding: 0,
                },
                align: "start",
                onHover: (event, activeElements) => {
                  (event?.native?.target as HTMLElement).style.cursor =
                    "pointer";
                },
              },
              tooltip: {
                // Disable the on-canvas tooltip
                enabled: false,

                external: function (context) {
                  // Tooltip Element
                  let tooltipEl = document.getElementById("chartjs-tooltip");

                  // Create element on first render
                  if (!tooltipEl) {
                    tooltipEl = document.createElement("div");
                    tooltipEl.id = "chartjs-tooltip";
                    tooltipEl.innerHTML = "<table></table>";
                    document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  const tooltipModel = context.tooltip;

                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = "0";
                    return;
                  }

                  // Set caret Position
                  tooltipEl.classList.remove("above", "below", "no-transform");
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add("no-transform");
                  }

                  function getBody(bodyItem: any) {
                    return bodyItem.lines;
                  }

                  // Set Text
                  if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(getBody);

                    let innerHtml = "<thead>";

                    bodyLines.forEach(function (body, i) {
                      let style = `
                                    background: white; 
                                    color: black; 
                                    box-shadow: 0px 20px 24px rgba(153, 155, 168, 0.18);
                                    position: absolute;
                                    top: calc(50% + 10px);
                                    left: calc(50% + 90px); 
                                    transform: translate(-50%, -50%); 
                                    display: flex; 
                                    flex-direction: column; 
                                    padding: 8px 18px; 
                                    border-radius: 2px; 
                                    max-width: 200px; 
                                    font-size: 16px;
                                    font-weight: 700;
                                    font-family: 'Roboto Flex Variable'; 
                                    font-weight: bold;
                                    width: 150px;
                                    height: 60px
                                    `;
                      const span = `
                                    <span style="${style}">
                                        <span style="font-size:14px; display: block;color: #5F646D; font-weight: 400; font-family: 'Roboto Flex Variable'">
                                            ${titleLines} 
                                        </span>
                                        ${body[0]}
                                    </span>
                                    `;
                      innerHtml += "<tr><td>" + span + "</td></tr>";
                    });
                    innerHtml += "</tbody>";

                    let tableRoot = tooltipEl.querySelector("table");
                    tableRoot!.innerHTML = innerHtml;
                  }

                  const position = context.chart.canvas.getBoundingClientRect();
                  const left =
                    position.left +
                    window.pageXOffset +
                    tooltipModel.caretX -
                    50;

                  // Display, position, and set styles for font
                  tooltipEl.style.opacity = "1";
                  tooltipEl.style.position = "absolute";
                  tooltipEl.style.left =
                    left + (window.innerWidth - left < 200 ? -100 : 0) + "px";
                  tooltipEl.style.top =
                    position.top +
                    window.pageYOffset +
                    tooltipModel.caretY -
                    33 +
                    "px";
                  tooltipEl.style.padding = "20px";
                  tooltipEl.style.pointerEvents = "none";
                },
                callbacks: {
                  title: function (context) {
                    return context[0].datasetIndex === 0
                      ? mainLabels[context[0].dataIndex]
                      : shortSecondaryLabels[context[0].dataIndex].replace(
                          "undefined",
                          ""
                        );
                  },
                },
              },
            },
          }}
        />
      </div>
    </>
  );
};

function formateDate(date: string, includeYear: boolean) {
  return moment(date).format(includeYear ? 'YYYY/MM/DD HH:mm' : 'MMM DD HH:mm')
}

export default DashboardChart;
