import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { z } from "zod";
import { ReactNode, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SubmitHandler,
  useForm,
  useWatch,
  Controller,
  FieldError,
  FieldErrors,
  UseFormTrigger,
} from "react-hook-form";
import TextInput from "../../../components/form/TextInput";
import { useNavigate, useParams } from "react-router-dom";
import Select, { GroupBase } from "react-select";
import MultiSelectCategoryOptionsSm from "../../../lib/react-select/MultiSelectCategoryOptionsSm";
import MultiSelectCategoryOptionsMdNoClear from "../../../lib/react-select/MultiSelectCategoryOptionsMdNoClear";
import { useAppDispatch, useAppSelector } from "../../../store";
import { apiSlice } from "../../../store/apiSlice";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import RichTextEditor, {
  getCharLength,
  getWordLength,
} from "../../../components/form/RichTextEditor";
import { globalActions } from "../../../store/globalSlice";
import { UnsavedModal } from "../../../components/global/amp/UnsavedModal";
import SingleSelectOption from "../../../lib/react-select/SingleSelectOption";
import { DropDownIndicator } from "./AttractionOpeningTimes";
import { hasUrl } from "../../../helpers/hasUrl";
import { PrimaryButton } from "../../../components/global/PrimaryButton";
import { OutlineButton } from "../../../components/global/OutlineButton";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";
import { MultiSelect } from "../../../components/form/MultiSelect";
import { unlockNewAttractionTab } from "../../../helpers/newAttractionsTabPermission";
import { filterPayload } from "../../../helpers/filterPayload";
import { RevertToSavedModal } from "../../../components/global/RevertToSavedModal";
import Stepper from "../../../components/amp/Stepper";
import ModalWrapper from "../../../components/global/ModalWrapper";
import MultiSelectOptions from "../../../lib/react-select/MultiSelectOptions";
import ClearIndicator from "../../../lib/react-select/ClearIndicator";
import { SaveButtonsEditAttraction } from "../../../components/amp/SaveButtonsEditAttraction";
import { StepperHeadingEditAttraction } from "../../../components/amp/StepperHeadingEditAttraction";
import { LightningIcon } from "../../../components/amp/LightBulbTooltip";
import { PointingArrowHintSm } from "../../../components/amp/PointingArrowHintSm";
import {
  clearNewAttractionStepLeftOver,
  getNewAttractionWhichStepLeftOver,
  setNewAttractionLeftOverTab,
} from "../../../helpers/newAttractionsFinishLater";
import {
  getVoScoreTooltip,
  isLightningIconActive,
  useVoScoreNeedImprovementsFields,
} from "../../../hooks/useVoScoreNeedImprovementsFields";

declare module "react-select/dist/declarations/src/Select" {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    customDefaultValue?:
      | { value: string | null | undefined; label: string | null | undefined }[]
      | any;
  }
}

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

interface AttractionDetailsForm {
  activityOptions: "indoor" | "outdoor" | "both";
  primaryCategory: string | null;
  secondaryCategory: string[];

  secondaryCategoryOptions: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
  minSuitableAge: number | null;
  maxSuitableAge: number | null;
  suitableForAdults: "yes" | "no";
  suggestionDuration:
    | "less_than_hour"
    | "1_2_hours"
    | "2_3_hours"
    | "3_4_hours"
    | "4_5_hours"
    | "more_five_hours";
  facilities: {
    value: string | null | undefined;
    label: string | null | undefined;
  }[];
  highlight1: string | null;
  highlight2: string | null;
  highlight3: string | null;
  highlight1_description: string | null;
  highlight2_description: string | null;
  highlight3_description: string | null;
  summary: string | null;
  descriptionHtml: string | undefined;
  testimonial: string | null;
}

const hiddenCategories = [
  "Baby",
  "Cashback",
  "E-Learning",
  "Fashion",
  "Money Matters",
  "Pet",
  "Utilities",
  "TV and Entertainment",
  "Toys",
];

const suggestedDurationOptions: {
  value: AttractionDetailsForm["suggestionDuration"];
  label: string;
}[] = [
  {
    value: "less_than_hour",
    label: "Less than 1 hour",
  },
  { value: "1_2_hours", label: "1 - 2 hours" },
  {
    value: "2_3_hours",
    label: "2 - 3 hours",
  },
  {
    value: "3_4_hours",
    label: "3 - 4 hours",
  },
  {
    value: "4_5_hours",
    label: "4 - 5 hours",
  },
  {
    value: "more_five_hours",
    label: "More than 5 hours",
  },
];

const getFacilityOptions = [
  {
    value: "TOILETS",
    label: "Toilets",
  },
  {
    value: "FREE_WIFI",
    label: "Free wifi",
  },
  {
    value: "GIFT_SHOP",
    label: "Gift shop",
  },
  {
    value: "CHANGING_ROOMS",
    label: "Changing Rooms",
  },
  {
    value: "ONSITE_PARKING",
    label: "Onsite Parking",
  },
  {
    value: "ACCESSIBLE_TOILETS",
    label: "Accessible Toilets",
  },
  {
    value: "BABY_CHANGING",
    label: "Baby Changing",
  },
  {
    value: "ATM",
    label: "ATM",
  },
  {
    value: "FOOD_AVAILABLE",
    label: "Food Available",
  },
  {
    value: "WHEELCHAIR_FRIENDLY",
    label: "Wheelchair Friendly",
  },
  {
    value: "DOG_FRIENDLY",
    label: "Dog Friendly",
  },
  {
    value: "PUSHCHAIR_FRIENDLY",
    label: "Pushchair Friendly",
  },
  {
    value: "CARD_PAYMENTS_ACCEPTED",
    label: "Card Payments Accepted",
  },
  {
    value: "WHEELCHAIR_HIRE",
    label: "Wheelchair Hire",
  },
  {
    value: "PUSHCHAIR_HIRE",
    label: "Pushchair Hire",
  },
];

const ages: { value: string; label: string }[] = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
];

const suitableForAdultsOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

const indoorOutdoorOptions = [
  {
    label: "Indoor",
    value: "indoor",
  },
  {
    label: "Outdoor",
    value: "outdoor",
  },
  {
    label: "Indoor & Outdoor",
    value: "both",
  },
];

const AttractionDetails: React.FC<IAttractionDetails> = () => {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [getShowRevertModal, setShowRevertModal] = useState<boolean>(false);

  const { attractionId } = useParams();
  const {
    data: getAttraction,
    isSuccess: isGetSuccess,
    isFetching,
    refetch,
    error: attractionError,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result } = getAttraction ?? {};

  const { data: getAttractionCategories } =
    apiSlice.useGetAttractionCategoriesQuery();
  const [updateAttraction, { isLoading, isError, isSuccess: isUpdateSuccess }] =
    apiSlice.useUpdateAttractionDraftMutation();

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors, isDirty },
    control,
    setValue,
    getValues,
    reset,
    trigger: triggerValidation,
  } = useForm<AttractionDetailsForm>({
    resolver: async (data, context, options) => {
      return zodResolver(schema)(data, context, options);
    },
    defaultValues: {
      primaryCategory: "",
      secondaryCategory: [],

      secondaryCategoryOptions: [
        {
          value: null,
          label: null,
        },
      ],
      facilities: [
        {
          value: null,
          label: null,
        },
      ],
      highlight1: null,
      highlight2: null,
      highlight3: null,
      highlight1_description: null,
      highlight2_description: null,
      highlight3_description: null,
      summary: "",
      descriptionHtml: "",
    },
  });
  const watchedHighlight1 = useWatch({ control, name: "highlight1" });
  const watchedHighlight2 = useWatch({ control, name: "highlight2" });
  const watchedHighlight3 = useWatch({ control, name: "highlight3" });

  const schema = z
    .object({
      activityOptions: z.enum(["indoor", "outdoor", "both"], {
        invalid_type_error: "Required",
      }),
      suitableForAdults: z.enum(["yes", "no"], {
        invalid_type_error: "Required",
      }),
      primaryCategory: z
        .string()
        .min(1, { message: "Please pick at least 1 primary category" }),
      secondaryCategory: z.string().array().max(3, {
        message: "Can only select a maximum of 3 secondary categories",
      }),

      secondaryCategoryOptions: z
        .array(z.object({ value: z.union([z.string(), z.null()]) }))
        .max(4, {
          message: "Can only select a maximum of 3 secondary categories",
        }),
      highlight1: z
        .string()
        .max(255, "Highlight is too long.")
        .min(1, { message: "Required" })
        .nullable()
        .superRefine((val, ctx) => {
          if (!val) return;
          if (val.trim().split(" ").length > 10) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Too long",
            });
          }
        }),
      highlight2: z
        .string()
        .max(255, "Highlight is too long.")
        .min(1, { message: "Required" })
        .nullable()
        .superRefine((val, ctx) => {
          if (!val) return;
          if (val.trim().split(" ").length > 10) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Too long",
            });
          }
        }),
      highlight3: z
        .string()
        .max(255, "Highlight is too long.")
        .min(1, { message: "Required" })
        .nullable()
        .superRefine((val, ctx) => {
          if (!val) return;
          if (val.trim().split(" ").length > 10) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Too long",
            });
          }
        }),
      highlight1_description: z
        .string()
        .nullable()
        .superRefine((value, ctx) => {
          if (hasUrl(value ?? "")) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `You are not allowed include a url.`,
            });
          }

          const wordLength = getWordLength(value ?? "");
          const maxCharCount = 200;

          if (wordLength > maxCharCount) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: ``,
            });
          }

          const charLen = getCharLength(value?.trim() ?? "");
          if (charLen === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Required",
            });
          }
        }),
      highlight2_description: z
        .string()
        .nullable()
        .superRefine((value, ctx) => {
          if (hasUrl(value ?? "")) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `You are not allowed include a url.`,
            });
          }

          const wordLength = getWordLength(value ?? "");
          const maxCharCount = 200;

          if (wordLength > maxCharCount) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: ``,
            });
          }

          const charLen = getCharLength(value?.trim() ?? "");
          if (charLen === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Required",
            });
          }
        }),
      highlight3_description: z
        .string()
        .nullable()
        .superRefine((value, ctx) => {
          if (hasUrl(value ?? "")) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `You are not allowed include a url.`,
            });
          }

          const wordLength = getWordLength(value ?? "");
          const maxCharCount = 200;

          if (wordLength > maxCharCount) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: ``,
            });
          }

          const charLen = getCharLength(value?.trim() ?? "");
          if (charLen === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Required",
            });
          }
        }),
      descriptionHtml: z.string().superRefine((value, ctx) => {
        const charLen = getCharLength(value?.trim() ?? "");
        if (charLen === 0 || value.trim().length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Required",
          });
        }

        if (hasUrl(value ?? "")) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `You are not allowed include a url.`,
          });
        }

        const wordLength = getWordLength(value ?? "");
        const maxCharCount = 1000;

        if (wordLength > maxCharCount) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: ``,
          });
        }
      }),
      summary: z
        .string()
        .min(1, { message: "Required" })
        .superRefine((value, ctx) => {
          if (hasUrl(value ?? "")) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `You are not allowed include a url.`,
            });
          }
          if (value.split(" ").length > 20) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Too long.",
            });
          }
        }),
      testimonial: z
        .string()
        .nullable()
        .superRefine((value, ctx) => {
          if (hasUrl(value ?? "")) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `You are not allowed include a url.`,
            });
          }
        }),
      maxSuitableAge: z.string().or(z.number()),
      minSuitableAge: z.string().or(z.number()),
      suggestionDuration: z.enum(
        [
          "1_2_hours",
          "3_4_hours",
          "2_3_hours",
          "4_5_hours",
          "less_than_hour",
          "more_five_hours",
        ],
        {
          invalid_type_error: "Required",
        }
      ),
    })
    .superRefine((value, ctx) => {
      // Unique selling points validation
      ([1, 2, 3] as const).forEach((number) => {
        if (
          value[`highlight${number}`] &&
          getWordLength(value[`highlight${number}_description`] ?? "") <= 0
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`highlight${number}_description`],
            message: `Description is required.`,
          });
        }

        if (
          !value[`highlight${number}`] &&
          getWordLength(value[`highlight${number}_description`] ?? "") > 0
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`highlight${number}`],
            message: `Title is required.`,
          });
        }
      });
    });

  const [isPublishing, setIsPublishing] = useState(false);
  const [stepsFields] = useState<(keyof AttractionDetailsForm)[][]>([
    ["activityOptions", "primaryCategory", "secondaryCategory"],
    [
      "minSuitableAge",
      "maxSuitableAge",
      "suitableForAdults",
      "suggestionDuration",
      "facilities",
    ],
    [
      "highlight1",
      "highlight1_description",
      "highlight2",
      "highlight2_description",
      "highlight3",
      "highlight3_description",
    ],
    ["summary", "descriptionHtml"],
  ]);
  const [stepsPostFields] = useState<string[][]>([
    [
      "indoor",
      "outdoor",
      "category_1_name",
      "category_2_name",
      "category_3_name",
      "category_4_name",
    ],
    [
      "suitable_child_age_min",
      "suitable_child_age_max",
      "suitable_for_adults",
      "suggested_visit_duration",
      "facilities",
    ],
    [
      "highlight_1",
      "highlight_1_description",
      "highlight_2",
      "highlight_2_description",
      "highlight_3",
      "highlight_3_description",
    ],
    ["summary", "description_html"],
  ]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = stepsFields[currentStepIndex] ?? [];
  const currentPostStep = stepsPostFields[currentStepIndex] ?? [];
  const { user } = useAppSelector((state) => state.global);
  const isStepperActive =
    user?.role !== "ADMIN" && !result?.finished_onboarding;

  const getPayload = () => {
    const payload: Record<string, any> = {
      indoor: ["indoor", "both"].includes(getValues("activityOptions") ?? ""),
      outdoor: ["outdoor", "both"].includes(getValues("activityOptions") ?? ""),
      category_1_name: getValues("primaryCategory") || null,
      category_2_name: getValues("secondaryCategory")[0] || null,
      category_3_name: getValues("secondaryCategory")[1] || null,
      category_4_name: getValues("secondaryCategory")[2] || null,
      suitable_child_age_min: getValues("minSuitableAge") ?? null,
      suitable_child_age_max: getValues("maxSuitableAge") ?? null,
      suitable_for_adults:
        getValues("suitableForAdults") === "yes"
          ? true
          : getValues("suitableForAdults") === "no"
          ? false
          : null,
      suggested_visit_duration: getValues("suggestionDuration"),
      facilities: getValues("facilities").map((f) => f.value) ?? null,
      highlight_1: getValues("highlight1") ?? null,
      highlight_2: getValues("highlight2") ?? null,
      highlight_3: getValues("highlight3") ?? null,
      highlight_1_description:
        getValues("highlight1") && getValues("highlight1_description")
          ? getValues("highlight1_description")
          : null,
      highlight_2_description:
        getValues("highlight2") && getValues("highlight2_description")
          ? getValues("highlight2_description")
          : null,
      highlight_3_description:
        getValues("highlight3") && getValues("highlight3_description")
          ? getValues("highlight3_description")
          : null,
      summary: getValues("summary") ?? "",
      description_html: getValues("descriptionHtml") ?? "",
      testimonial: getValues("testimonial") ?? "",
    };

    return payload;
  };
  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<AttractionDetailsForm> = async (e) => {
      setIsPublishing(publish);
      if (result) {
        const payload = filterPayload(
          getPayload(),
          currentPostStep,
          isStepperActive
        );
        const results = await updateAttraction({
          id: attractionId,
          publish,
          payload,
        });
        if (!isSilent) {
          restartAnimation();
        }

        return "data" in results;
      }

      return false;
    };

    return fn;
  };

  const getAge = (
    value: string | null,
    additional: { label: string; value: string }[] = []
  ) => {
    if (!value) {
      return null;
    }
    return [...additional, ...ages].find((c) => c.value === value);
  };

  function getIndoorOutdoorEnum(indoor: boolean, outdoor: boolean) {
    if (indoor && outdoor) return "both";
    if (indoor) return "indoor";
    if (outdoor) return "outdoor";
    return null;
  }

  useEffect(() => {
    if (result && isGetSuccess && !isFetching) {
      const activityOption = getIndoorOutdoorEnum(
        result?.indoor === true,
        result?.outdoor === true
      );
      if (activityOption) {
        setValue("activityOptions", activityOption);
      }
      setValue("primaryCategory", result?.category_1_name ?? "");

      const secondaryCats: string[] = [
        result?.category_2_name || "",
        result?.category_3_name || "",
        result?.category_4_name || "",
      ].filter((v) => v);
      setValue("secondaryCategory", secondaryCats);

      setValue("minSuitableAge", result?.suitable_child_age_min ?? null, {
        shouldDirty: false,
      });
      setValue("maxSuitableAge", result?.suitable_child_age_max ?? null, {
        shouldDirty: false,
      });
      if (result?.suggested_visit_duration) {
        setValue(
          "suggestionDuration",
          result?.suggested_visit_duration as AttractionDetailsForm["suggestionDuration"]
        );
      }

      const allFacilities = typeof result?.facilities === 'string' ? (result?.facilities as string)?.replace('{', '').replace('}', '').split(',') : []
      setValue(
        "facilities",
        getFacilityOptions.filter((f) => allFacilities.filter(a => a === f.value).length > 0)
      );
      setValue("highlight1", result?.highlight_1 ?? "");
      setValue("highlight2", result?.highlight_2 ?? "");
      setValue("highlight3", result?.highlight_3 ?? "");
      setValue("highlight1_description", result?.highlight_1 ?? "");
      setValue("highlight2_description", result?.highlight_2 ?? "");
      setValue("highlight3_description", result?.highlight_3 ?? "");
      setValue("summary", result?.summary ?? "");
      setValue("descriptionHtml", result?.description_html ?? "");
      setValue("testimonial", result?.testimonial ?? "");

      const suitableForAdults =
        result?.suitable_for_adults === true
          ? "yes"
          : result?.suitable_for_adults === false
          ? "no"
          : null;

      if (suitableForAdults) {
        setValue("suitableForAdults", suitableForAdults);
      }

      reset(
        {
          ...getValues(),
        },
        {
          keepDirty: false,
        }
      );
      setShowRevertModal(false);
    }
  }, [result, isFetching]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Attraction Details | Day Out With The Kids";
  }, []);

  const bypassQueue =
    user?.role === "ADMIN" ||
    ["ENHANCED", "PREMIUM"].includes(result?.listing_package ?? "");
  useEffect(() => {
    if (isUpdateSuccess) {
      reset({}, { keepValues: true });

      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: isPublishing
            ? bypassQueue
              ? "Published"
              : "Submitted for approval"
            : "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: isPublishing
            ? "Cannot publish attraction"
            : "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError]);

  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 404
    ) {
      navigate("/not-found", { replace: true });
    }
  }, [attractionError]);

  const secondaryCategories = (getAttractionCategories ?? [])
    .map((c) => ({
      value: c.name,
      label: c.name,
    }))
    .sort((a, b) => a.label!.localeCompare(b.label!))
    .filter((v) => v.value)
    .filter((v) => getValues("primaryCategory") !== v.value)
    .filter((v) => {
      return !hiddenCategories.includes(v.value!);
    });

  const stepperStep = useState(0);
  useEffect(() => {
    const steps = getNewAttractionWhichStepLeftOver();
    if (steps.step && steps.allSteps) {
      if (steps.allSteps === stepsFields.length) {
        stepperStep[1](steps.step);
        setCurrentStepIndex(steps.step);
      }
      clearNewAttractionStepLeftOver();
    }
  }, []);

  useEffect(() => {
    dispatch(
      globalActions.setSaveCurrentTabStep({
        attractionId: attractionId ?? "",
        key: "ATTRACTION_DETAILS",
        allSteps: stepsFields.length,
        step: currentStepIndex,
      })
    );
  }, [attractionId, currentStepIndex, stepsFields.length, dispatch]);

  const { data: getMetrics } = apiSlice.useGetVenueOptScoreMetricsQuery();
  const voScoreImprovements = useVoScoreNeedImprovementsFields({
    allMetrics: getMetrics?.result || [],
    completedMetrics:
      getAttraction?.result?.published_version?.optimisation_scores || [],
    results: result,
  })["ATTRACTION_DETAILS"];

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        triggerValidation={handleSubmit}
        saveFn={onSubmit}
        getPayload={getPayload}
        isDirty={isDirty}
      />
      <form className="" onSubmit={handleSubmit(onSubmit(false))}>
        <SingleAttractionTemplate
          result={result}
          attractionId={attractionId}
          doneWalkthrough={
            result ? result?.done_walkthrough ?? false : undefined
          }
          isAttraction={true}
          name={isStepperActive ? result?.name ?? "" : ""}
          className="xl:mt-[130px] mb-10"
          unstyled={!isStepperActive}
        >
          <Stepper
            controlledStepping={stepperStep}
            isActive={isStepperActive}
            isLoading={isLoading}
            saveFn={onSubmit}
            onStepChange={setCurrentStepIndex}
            triggerValidation={async () => {
              return await triggerValidation(currentStep);
            }}
            onNextPage={async () => {
              unlockNewAttractionTab(attractionId ?? "", "ATTRACTION_LOCATION");
              await new Promise((res) => setTimeout(res, 500));
              navigate(`/attractions/${attractionId}/location`);
            }}
            title={
              <StepperHeadingEditAttraction
                name={result?.name || ""}
                title={
                  currentStepIndex === 2
                    ? "🏰 What Makes Your Attraction Special?"
                    : currentStepIndex === 3
                    ? "🏰 Short & Long Description"
                    : "🏰 Attraction Details"
                }
                isActive={isStepperActive}
              />
            }
            nonStepperSubmit={
              <SaveButtonsEditAttraction isLoading={isLoading} />
            }
            onBeforeFinishLater={() => {
              setNewAttractionLeftOverTab(
                attractionId ?? "",
                "ATTRACTION_DETAILS",
                currentStepIndex,
                stepsFields.length
              );
            }}
            onGoPrevPage={() => {
              navigate(`/attractions/${attractionId}/overview`);
            }}
          >
            <Stepper.Step componentType="Step">
              <fieldset>
                <div className="w-full md:w-3/4">
                  <p className="text-black text-sm font-bold">
                    Is your attraction indoor, outdoor or both?{" "}
                    {!isStepperActive &&
                      !result?.indoor &&
                      !result?.outdoor &&
                      !!voScoreImprovements.activityOptions && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.activityOptions
                          )}
                          className="ml-1"
                        />
                      )}
                  </p>

                  <Controller
                    control={control}
                    name="activityOptions"
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        <Select
                          onChange={(value, meta) => {
                            onChange(value?.value ?? null);
                          }}
                          value={indoorOutdoorOptions.find(
                            (v) => v.value === value
                          )}
                          defaultValue={null}
                          isClearable={false}
                          closeMenuOnSelect={false}
                          menuIsOpen={true}
                          hideSelectedOptions={false}
                          options={indoorOutdoorOptions}
                          components={{ Option: MultiSelectCategoryOptionsSm }}
                          placeholder={""}
                          styles={{
                            multiValue: (styles) => ({
                              ...styles,
                              backgroundColor: "#6836D1",
                              borderRadius: 20,
                              height: 40,
                              display: "flex",
                              alignItems: "center",
                            }),
                            multiValueRemove: () => ({
                              display: "none",
                            }),
                            multiValueLabel: (styles) => ({
                              ...styles,
                              color: "#FFF",
                              paddingLeft: 10,
                              paddingRight: 10,
                              fontSize: 14,
                              fontWeight: 400,
                            }),
                            menu: (styles) => ({
                              ...styles,
                              margin: 0,
                              paddingLeft: 5,
                              border: "0",
                              overflow: "hidden",
                              boxShadow: "none",
                              display: "flex",
                              width: "100%",
                              position: "relative",
                            }),
                            menuList: (base) => ({
                              ...base,
                              marginTop: 8,
                              marginBottom: 0,
                              overflow: "hidden",
                              display: "flex",
                              gap: 12,
                            }),
                            option: (base) => ({
                              ...base,
                              background: "red",
                              padding: "0",
                            }),
                            noOptionsMessage: (base) => ({
                              ...base,
                              display: "none",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              display: "none",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            background: "none",
                            colors: {
                              ...theme.colors,
                              neutral80: "#555",
                            },
                          })}
                        />
                        {error && <ErrorMessage>{error.message}</ErrorMessage>}
                      </>
                    )}
                  />
                </div>

                <div className="w-full md:w-3/4 xl:w-1/2 mt-3">
                  <label className="text-black font-bold text-sm">
                    Primary category <span className="text-[#6836D1]">*</span>{" "}
                    {!isStepperActive &&
                      !result?.category_1_name &&
                      !!voScoreImprovements.primaryCategory && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.primaryCategory
                          )}
                          className="ml-1"
                        />
                      )}
                  </label>
                  <p className="font-normal text-sm text-[#5F646D] mb-3 mt-1">
                    Select a category that your attraction is most known for.
                  </p>

                  {isStepperActive ? (
                    <Controller
                      control={control}
                      name={`primaryCategory`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <div className="max-w-[437px]">
                          <DropdownPrimaryCategory
                            error={error}
                            value={value ?? ""}
                            onChange={onChange}
                            categories={getAttractionCategories ?? []}
                          />
                        </div>
                      )}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={`primaryCategory`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ModalPrimaryCategory
                          value={value ?? ""}
                          onChange={onChange}
                          categories={(getAttractionCategories ?? []).filter(
                            (v) => !hiddenCategories.includes(v.name!)
                          )}
                          errors={errors}
                          isSubmitted={isSubmitted}
                          isLoading={isLoading}
                          triggerValidation={triggerValidation}
                        />
                      )}
                    />
                  )}

                  {errors.primaryCategory && (
                    <div className="text-cs-red flex items-center my-2">
                      <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                      <span className="flex-1">
                        {errors.primaryCategory?.message}
                      </span>
                    </div>
                  )}
                </div>

                <div
                  className={`w-full mt-5 ${isStepperActive ? "md:w-3/4" : ""}`}
                >
                  <label className="text-black font-bold text-sm mt-5 mb-3">
                    Secondary categories [optional] - choose up to 3 *
                  </label>

                  <p className="text-sm text-[#5F646D] font-normal my-2">
                    Select a few more categories that can be used to describe
                    your attraction.
                  </p>

                  {isStepperActive ? (
                    <Controller
                      control={control}
                      name={`secondaryCategory`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <div className="max-w-[437px]">
                          <DropdownSecondaryCategory
                            value={value}
                            onChange={onChange}
                            categories={secondaryCategories}
                          />
                        </div>
                      )}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={`secondaryCategory`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ModalSecondaryCategory
                          value={value ?? ""}
                          onChange={onChange}
                          categories={(getAttractionCategories ?? []).filter(
                            (v) => !hiddenCategories.includes(v.name!)
                          )}
                          errors={errors}
                          isSubmitted={isSubmitted}
                          isLoading={isLoading}
                          triggerValidation={triggerValidation}
                        />
                      )}
                    />
                  )}

                  {errors.secondaryCategoryOptions && (
                    <ErrorMessage>
                      {errors.secondaryCategoryOptions?.message}
                    </ErrorMessage>
                  )}

                  <p className="text-xs text-[#5F646D] font-normal mt-3">
                    * Categories will be reviewed by our team, and may be
                    recategorised by us if necessary.
                    <br /> You will be notified by email of any changes.
                  </p>

                  <p className="text-sm text-[#5F646D] font-normal mt-3">
                    Think we are missing a category? Request one{" "}
                    <a
                      href="mailto:connect@dayoutwiththekids.co.uk"
                      className="underline"
                    >
                      here.
                    </a>
                  </p>
                </div>
              </fieldset>
            </Stepper.Step>

            <Stepper.Step componentType="Step" grouping={"a"}>
              <fieldset>
                <div className="w-full md:w-3/4">
                  <legend className="text-black font-bold text-sm mb-2.5">
                    {isStepperActive
                      ? "Can Adults participate too?"
                      : "Are the activities at your attraction suitable for adults to take part in, too?"}
                  </legend>

                  <Controller
                    control={control}
                    name="suitableForAdults"
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        <Select
                          onChange={(value, meta) => {
                            if (value?.value) {
                              onChange(value.value);
                            }
                          }}
                          value={suitableForAdultsOptions.find(
                            (v) => v.value === value
                          )}
                          defaultValue={null}
                          isClearable={false}
                          closeMenuOnSelect={false}
                          menuIsOpen={true}
                          hideSelectedOptions={false}
                          options={suitableForAdultsOptions}
                          components={{ Option: MultiSelectCategoryOptionsSm }}
                          placeholder={""}
                          captureMenuScroll={false}
                          styles={{
                            multiValue: (styles) => ({
                              ...styles,
                              backgroundColor: "#6836D1",
                              borderRadius: 20,
                              height: 40,
                              display: "flex",
                              alignItems: "center",
                            }),
                            multiValueRemove: () => ({
                              display: "none",
                            }),
                            multiValueLabel: (styles) => ({
                              ...styles,
                              color: "#FFF",
                              paddingLeft: 10,
                              paddingRight: 10,
                              fontSize: 14,
                              fontWeight: 400,
                            }),
                            menu: (styles) => ({
                              ...styles,
                              margin: 0,
                              paddingLeft: 5,
                              border: "0",
                              position: "relative",
                              overflow: "hidden",
                              boxShadow: "none",
                              display: "flex",
                              width: "100%",
                            }),
                            menuList: (base) => ({
                              ...base,
                              marginTop: 0,
                              marginBottom: 0,
                              overflow: "hidden",
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              margin: "0 -10px",
                            }),
                            option: (base) => ({
                              ...base,
                              margin: "0 5px",
                            }),
                            noOptionsMessage: (base) => ({
                              ...base,
                              color: "#555",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              display: "none",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            background: "none",
                            colors: {
                              ...theme.colors,
                              neutral80: "#555",
                            },
                          })}
                        />

                        {error && <ErrorMessage>{error.message}</ErrorMessage>}
                      </>
                    )}
                  />
                </div>

                <div className="w-full md:w-3/4 xl:w-[60%] max-w-[570px] mt-5">
                  <div className="flex items-center">
                    <legend className="text-black font-bold text-sm">
                      How long would you recommend families to spend at your
                      attraction?{" "}
                      {!isStepperActive &&
                        !!voScoreImprovements.suggestionDuration &&
                        !result?.suggested_visit_duration && (
                          <LightningIcon
                            tooltip={getVoScoreTooltip(
                              voScoreImprovements.suggestionDuration
                            )}
                            className="ml-1"
                          />
                        )}
                    </legend>
                  </div>

                  <Controller
                    control={control}
                    name="suggestionDuration"
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        <Select
                          onChange={(value, meta) => {
                            if (value?.value) {
                              onChange(value?.value);
                            }
                          }}
                          value={suggestedDurationOptions.find(
                            (v) => v.value === value
                          )}
                          defaultValue={null}
                          isClearable={undefined}
                          closeMenuOnSelect={false}
                          menuIsOpen={true}
                          hideSelectedOptions={false}
                          options={suggestedDurationOptions}
                          components={{ Option: MultiSelectCategoryOptionsMdNoClear }}
                          placeholder={""}
                          captureMenuScroll={false}
                          styles={{
                            multiValue: (styles) => ({
                              ...styles,
                              backgroundColor: "#6836D1",
                              borderRadius: 20,
                              height: 40,
                              display: "flex",
                              alignItems: "center",
                            }),
                            multiValueRemove: () => ({
                              display: "none",
                            }),
                            multiValueLabel: (styles) => ({
                              ...styles,
                              color: "#FFF",
                              paddingLeft: 10,
                              paddingRight: 10,
                              fontSize: 14,
                              fontWeight: 400,
                            }),
                            menu: (styles) => ({
                              ...styles,
                              margin: 0,
                              paddingLeft: 5,
                              border: "0",
                              position: "relative",
                              overflow: "hidden",
                              boxShadow: "none",
                              display: "flex",
                              width: "100%",
                            }),
                            menuList: (base) => ({
                              ...base,
                              marginTop: 0,
                              marginBottom: 0,
                              overflow: "hidden",
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              margin: "0 -10px",
                            }),
                            option: (base) => ({
                              ...base,
                              margin: "5px",
                            }),
                            noOptionsMessage: (base) => ({
                              ...base,
                              color: "#555",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              display: "none",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            background: "none",
                            colors: {
                              ...theme.colors,
                              neutral80: "#555",
                            },
                          })}
                        />

                        {error && <ErrorMessage>{error.message}</ErrorMessage>}
                      </>
                    )}
                  />
                </div>

                <div className="w-full md:w-3/4 mt-3">
                  <legend className="text-black font-bold text-sm mb-3">
                    Select all facilities that apply to your attraction
                    {!isStepperActive &&
                      !!voScoreImprovements.facilities &&
                      (result?.facilities?.length ?? 0) < 3 && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.facilities
                          )}
                          className="ml-2"
                        />
                      )}
                  </legend>

                  <div className="w-full md:w-3/4 xl:w-[437px]">
                    <Controller
                      control={control}
                      name="facilities"
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <MultiSelect
                          onChange={onChange}
                          value={value}
                          defaultValue={null}
                          isMulti
                          placeholder="Select facilities"
                          options={getFacilityOptions}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="mt-5">
                  <legend className="text-black font-bold text-sm mb-1">
                    Most suitable child age range for this attraction{" "}
                    {!isStepperActive &&
                      (result?.suitable_child_age_min === null ||
                        result?.suitable_child_age_max === null) &&
                      !!voScoreImprovements.minSuitableAge &&
                      !!voScoreImprovements.maxSuitableAge && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.minSuitableAge ||
                              voScoreImprovements.maxSuitableAge
                          )}
                          className="ml-1"
                        />
                      )}
                  </legend>
                  <div className="flex">
                    <div className="relative mr-2">
                      <label className="font-normal text-xs text-[#5F646D]">
                        Minimum age
                      </label>
                      <Controller
                        control={control}
                        name={`minSuitableAge`}
                        render={({
                          field: { ref, value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <Select
                              components={{
                                Option: SingleSelectOption,
                                DropdownIndicator: DropDownIndicator,
                                IndicatorSeparator: () => null,
                              }}
                              value={getAge(value ? value.toString() : null, [
                                { label: "No min", value: "-1" },
                              ])}
                              options={[
                                { label: "No min", value: "-1" },
                                ...ages,
                              ]}
                              isSearchable={true}
                              styles={{
                                menu: (styles) => ({
                                  ...styles,
                                  margin: 0,
                                  border: "none",
                                  zIndex: 15,
                                }),
                                menuList: (base, isSelected) => ({
                                  ...base,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  background: "white",

                                  "::-webkit-scrollbar": {
                                    width: 14,
                                  },
                                  "::-webkit-scrollbar-thumb": {
                                    background: "#c2c2c2",
                                    borderRadius: 10,
                                    border: "4px solid white",
                                  },
                                }),
                                option: (
                                  base,
                                  { data, isDisabled, isFocused, isSelected }
                                ) => {
                                  return {
                                    ...base,
                                    background: "white",
                                    border: "1px solid #c2c2c2",
                                    color: isSelected ? "white" : "#555",
                                    "&:hover": {
                                      backgroundColor: "#6836D1",
                                      color:
                                        isSelected || isFocused
                                          ? "white"
                                          : "#555",
                                      border: isFocused
                                        ? "1px solid white"
                                        : "",
                                    },
                                    "&:active": {
                                      backgroundColor: "#6836D1",
                                      color: "white",
                                    },
                                  };
                                },
                                singleValue: (base) => ({
                                  ...base,
                                  color: "#555",
                                  fontSize: 14,
                                }),
                                control: (styles, state) => ({
                                  ...styles,
                                  cursor: "pointer",
                                  outline: "none",
                                  border: state.isFocused
                                    ? "1px solid #CED3CF"
                                    : `1px solid ${error ? "red" : "#CED3CF"}`,
                                  "&:hover": {
                                    border: state.isFocused
                                      ? "1px solid #CED3CF"
                                      : "1px solid #CED3CF",
                                  },
                                  boxShadow: "none",
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                  margin: 0,
                                  minHeight: 40,
                                  height: 40,
                                  width: 168,
                                  borderRadius: 8,
                                  paddingLeft: 12,
                                }),
                              }}
                              onChange={(v) => {
                                onChange(Number.parseInt(v?.value!));
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div className="relative ml-2">
                      <label className="font-normal text-xs text-[#5F646D]">
                        Maximum age
                      </label>
                      <Controller
                        control={control}
                        name={`maxSuitableAge`}
                        render={({
                          field: { ref, value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <Select
                              components={{
                                Option: SingleSelectOption,
                                DropdownIndicator: () => (
                                  <div className="px-2.5">
                                    <svg
                                      width="10"
                                      height="6"
                                      viewBox="0 0 10 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                ),
                                IndicatorSeparator: () => null,
                              }}
                              value={getAge(value ? value.toString() : null, [
                                { label: "No max", value: "-1" },
                              ])}
                              options={[
                                { label: "No max", value: "-1" },
                                ...ages,
                              ]}
                              isSearchable={true}
                              styles={{
                                menu: (styles) => ({
                                  ...styles,
                                  margin: 0,
                                  border: "none",
                                  zIndex: 15,
                                }),
                                menuList: (base, isSelected) => ({
                                  ...base,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  background: "white",

                                  "::-webkit-scrollbar": {
                                    width: 14,
                                  },
                                  "::-webkit-scrollbar-thumb": {
                                    background: "#c2c2c2",
                                    borderRadius: 10,
                                    border: "4px solid white",
                                  },
                                }),
                                option: (
                                  base,
                                  { data, isDisabled, isFocused, isSelected }
                                ) => {
                                  return {
                                    ...base,
                                    background: "white",
                                    border: "1px solid #c2c2c2",
                                    color: isSelected ? "white" : "#555",
                                    "&:hover": {
                                      backgroundColor: "#6836D1",
                                      color:
                                        isSelected || isFocused
                                          ? "white"
                                          : "#555",
                                      border: isFocused
                                        ? "1px solid white"
                                        : "",
                                    },
                                    "&:active": {
                                      backgroundColor: "#6836D1",
                                      color: "white",
                                    },
                                  };
                                },
                                singleValue: (base) => ({
                                  ...base,
                                  color: "#555",
                                  fontSize: 14,
                                }),
                                control: (styles, state) => ({
                                  ...styles,
                                  cursor: "pointer",
                                  outline: "none",
                                  border: state.isFocused
                                    ? "1px solid #CED3CF"
                                    : `1px solid ${error ? "red" : "#CED3CF"}`,
                                  "&:hover": {
                                    border: state.isFocused
                                      ? "1px solid #CED3CF"
                                      : "1px solid #CED3CF",
                                  },
                                  boxShadow: "none",
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                  margin: 0,
                                  minHeight: 40,
                                  height: 40,
                                  width: 168,
                                  borderRadius: 8,
                                  paddingLeft: 12,
                                }),
                              }}
                              onChange={(v) => {
                                onChange(Number.parseInt(v?.value!));
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>

                  {(errors["minSuitableAge"] || errors["maxSuitableAge"]) && (
                    <ErrorMessage>Required</ErrorMessage>
                  )}
                </div>

                {!isStepperActive && (
                  <div className="mt-5">
                    <ShortDescription
                      isStepperActive={isStepperActive}
                      control={control}
                      errors={errors["summary"]?.message}
                      voImprovement={
                        !!voScoreImprovements.summary && (
                          <LightningIcon
                            tooltip={getVoScoreTooltip(
                              voScoreImprovements.summary
                            )}
                            className="ml-1"
                          />
                        )
                      }
                    />

                   {result && <LongDescription
                      isStepperActive={isStepperActive}
                      control={control}
                      errors={errors["descriptionHtml"]?.message}
                      getValues={getValues}
                      setValue={setValue}
                      initialValue={result?.description_html ?? ""}
                      voImprovement={
                        isLightningIconActive(
                          voScoreImprovements.descriptionHtml
                        ) && (
                          <LightningIcon
                            tooltip={getVoScoreTooltip(
                              voScoreImprovements.descriptionHtml
                            )}
                            className="ml-1"
                          />
                        )
                      }
                    /> }
                  </div>
                )}
              </fieldset>
            </Stepper.Step>

            <Stepper.Step componentType="Step" grouping="a">
              <fieldset className="w-full md:w-3/4 xl:w-[600px] max-w-[600px]">
                <div>
                  {!isStepperActive && (
                    <legend className="text-sm font-extrabold text-[#212121] mb-2.5">
                      What makes your attraction special?
                    </legend>
                  )}
                  <p className="text-[#5F646D] font-normal text-sm">
                    We have over 8,000 listings on our site. What makes you
                    special?
                  </p>
                  <p className="text-[#5F646D] font-normal text-sm">
                    Tell us up to 3 things that makes your attraction stand out
                    against the rest.
                  </p>
                </div>

                <div>
                  <h4 className="text-black font-bold text-sm mt-5 flex items-center justify-between gap-1">
                    <span>
                      Unique Selling Point Title (max. 10 words){" "}
                      {!isStepperActive && !!voScoreImprovements.highlight1 && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.highlight1
                          )}
                          className="ml-1"
                        />
                      )}
                    </span>

                    <span className="text-sm text-cs-gray font-normal">
                      {watchedHighlight1
                        ?.trim()
                        ?.split(" ")
                        .filter((s) => s).length ?? 0}
                      /10 words
                    </span>
                  </h4>
                  <div className="relative">
                    <TextInput
                      className="group mt-1 w-full"
                      labelClassName="group-focus-within:text-cs-pink text-xs font-normal"
                      inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-lg py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                      control={control}
                      name={"highlight1"}
                      placeholder={
                        "Write a title that covers your first USP. For example, “The UK’s first...” "
                      }
                      label=""
                      inputProps={{
                        ...register("highlight1", {
                          maxLength: 255,
                        }),
                      }}
                    />

                    {isStepperActive && (
                      <PointingArrowHintSm
                        className="top-5 -right-[290px]"
                        boxClassName="w-[210px]"
                      >
                        <div>Why do families love your attraction?</div>
                      </PointingArrowHintSm>
                    )}
                  </div>
                  <div className={`mt-4`}>
                    <h4
                      className={`text-black font-bold text-sm mt-5 ${
                        user?.role === "ADMIN" ? "mb-[-25px]" : ""
                      }`}
                    >
                      Unique Selling Point Description (max. 200 words){" "}
                      {!isStepperActive && !!voScoreImprovements.highlight1 && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.highlight1
                          )}
                          className="ml-1"
                        />
                      )}
                    </h4>
                    {result?.highlight_1_description !== undefined && (
                      <RichTextEditor
                        maxChars={200}
                        maxCharSuffix={" words"}
                        isWordCount={true}
                        initialValue={result?.highlight_1_description ?? ""}
                        onChange={(value: string) =>
                          setValue("highlight1_description", value)
                        }
                        onDirty={() =>
                          setTimeout(() => {
                            setValue(
                              "highlight1_description",
                              getValues("highlight1_description"),
                              { shouldDirty: true }
                            );
                          }, 100)
                        }
                        placeholder="Ensure you emphasise what sets your Unique Selling Point apart. Everyone says that they have the best attraction in the UK, what unique aspect truly distinguishes your attraction and makes it the best?"
                        label=""
                      />
                    )}
                    {errors["highlight1_description"]?.message && (
                      <ErrorMessage>
                        {errors["highlight1_description"]?.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>

                {isStepperActive && (
                  <div className="bg-[#CFDBD5] h-[2px] my-5"></div>
                )}
                <div className="mt-5">
                  <h4 className="text-black font-bold text-sm mt-5 flex items-center justify-between gap-1">
                    <span>
                      Unique Selling Point Title (max. 10 words){" "}
                      {!isStepperActive && !!voScoreImprovements.highlight2 && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.highlight2
                          )}
                          className="ml-1"
                        />
                      )}
                    </span>

                    <span className="text-sm text-cs-gray font-normal">
                      {watchedHighlight2
                        ?.trim()
                        ?.split(" ")
                        .filter((s) => s).length ?? 0}
                      /10 words
                    </span>
                  </h4>
                  <div className="relative">
                    <TextInput
                      className="group mt-1 w-full"
                      labelClassName="group-focus-within:text-cs-pink text-xs font-normal"
                      inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-lg py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                      control={control}
                      name={"highlight2"}
                      placeholder={
                        "Write a title that covers your first USP. For example, “The UK’s first...” "
                      }
                      label=""
                      inputProps={{
                        ...register("highlight2", { maxLength: 255 }),
                      }}
                    />
                    {isStepperActive && (
                      <PointingArrowHintSm
                        className="top-5 -right-[290px]"
                        boxClassName="w-[210px]"
                      >
                        <div>
                          What makes your attraction different to others?
                        </div>
                      </PointingArrowHintSm>
                    )}
                  </div>
                  <div className={`mt-4`}>
                    <h4
                      className={`text-black font-bold text-sm mt-5 ${
                        user?.role === "ADMIN" ? "mb-[-25px]" : ""
                      }`}
                    >
                      Unique Selling Point Description (max. 200 words){" "}
                      {!isStepperActive && !!voScoreImprovements.highlight2 && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.highlight2
                          )}
                          className="ml-1"
                        />
                      )}
                    </h4>
                    {result?.highlight_2_description !== undefined && (
                      <RichTextEditor
                        maxChars={200}
                        maxCharSuffix={" words"}
                        isWordCount={true}
                        initialValue={result?.highlight_2_description ?? ""}
                        onChange={(value: string) =>
                          setValue("highlight2_description", value)
                        }
                        onDirty={() =>
                          setTimeout(() => {
                            setValue(
                              "highlight2_description",
                              getValues("highlight2_description"),
                              { shouldDirty: true }
                            );
                          }, 100)
                        }
                        placeholder="Ensure you emphasise what sets your Unique Selling Point apart. Everyone says that they have the best attraction in the UK, what unique aspect truly distinguishes your attraction and makes it the best?"
                        label=""
                      />
                    )}
                    {errors["highlight2_description"]?.message && (
                      <ErrorMessage>
                        {errors["highlight2_description"]?.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>

                {isStepperActive && (
                  <div className="bg-[#CFDBD5] h-[2px] my-5"></div>
                )}
                <div className="mt-5">
                  <h4 className="text-black font-bold text-sm mt-5 flex items-center justify-between gap-1">
                    <span>
                      Unique Selling Point Title (max. 10 words){" "}
                      {!isStepperActive && !!voScoreImprovements.highlight3 && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.highlight3
                          )}
                          className="ml-1"
                        />
                      )}
                    </span>

                    <span className="text-sm text-cs-gray font-normal">
                      {watchedHighlight3
                        ?.trim()
                        ?.split(" ")
                        .filter((s) => s).length ?? 0}
                      /10 words
                    </span>
                  </h4>
                  <div className="relative">
                    <TextInput
                      className="group mt-1 w-full"
                      labelClassName="group-focus-within:text-cs-pink text-xs font-normal"
                      inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-lg py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                      control={control}
                      name={"highlight3"}
                      placeholder={
                        "Write a title that covers your first USP. For example, “The UK’s first...” "
                      }
                      label=""
                      inputProps={{
                        ...register("highlight3", { maxLength: 255 }),
                      }}
                    />
                    {isStepperActive && (
                      <PointingArrowHintSm
                        className="top-5 -right-[290px]"
                        boxClassName="w-[210px]"
                      >
                        <div>What do kids love about your attraction?</div>
                      </PointingArrowHintSm>
                    )}
                  </div>
                  <div className={`mt-4`}>
                    <h4
                      className={`text-black font-bold text-sm mt-5 ${
                        user?.role === "ADMIN" ? "mb-[-25px]" : ""
                      }`}
                    >
                      Unique Selling Point Description (max. 200 words){" "}
                      {!isStepperActive && !!voScoreImprovements.highlight3 && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.highlight3
                          )}
                          className="ml-1"
                        />
                      )}
                    </h4>
                    {result?.highlight_3_description !== undefined && (
                      <RichTextEditor
                        maxChars={200}
                        maxCharSuffix={" words"}
                        isWordCount={true}
                        initialValue={result?.highlight_3_description ?? ""}
                        onChange={(value: string) =>
                          setValue("highlight3_description", value)
                        }
                        onDirty={() =>
                          setTimeout(() => {
                            setValue(
                              "highlight3_description",
                              getValues("highlight3_description"),
                              { shouldDirty: true }
                            );
                          }, 100)
                        }
                        placeholder="Ensure you emphasise what sets your Unique Selling Point apart. Everyone says that they have the best attraction in the UK, what unique aspect truly distinguishes your attraction and makes it the best?"
                        label=""
                      />
                    )}
                    {errors["highlight3_description"]?.message && (
                      <ErrorMessage>
                        {errors["highlight3_description"]?.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>
              </fieldset>
            </Stepper.Step>

            {isStepperActive && (
              <Stepper.Step componentType="Step" grouping="b">
                <fieldset>
                  <ShortDescription
                    isStepperActive={isStepperActive}
                    control={control}
                    errors={errors["summary"]?.message}
                    voImprovement={
                      !!voScoreImprovements.summary && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.summary
                          )}
                          className="ml-1"
                        />
                      )
                    }
                  />

                  {result && <LongDescription
                    isStepperActive={isStepperActive}
                    control={control}
                    errors={errors["descriptionHtml"]?.message}
                    getValues={getValues}
                    setValue={setValue}
                    initialValue={result?.description_html ?? ""}
                    voImprovement={
                      isLightningIconActive(
                        voScoreImprovements.descriptionHtml
                      ) && (
                        <LightningIcon
                          tooltip={getVoScoreTooltip(
                            voScoreImprovements.descriptionHtml
                          )}
                          className="ml-1"
                        />
                      )
                    }
                  /> }
                </fieldset>
              </Stepper.Step>
            )}
          </Stepper>

          {isSubmitted && !isValid && (
            <ErrorMessage>
              You have some errors! Scroll up to view & fix
            </ErrorMessage>
          )}
        </SingleAttractionTemplate>

        <RevertToSavedModal
          getShowRevertModal={getShowRevertModal}
          setShowRevertModal={setShowRevertModal}
          refetch={refetch}
        />

        <UnsavedModal isDirty={isDirty} />
      </form>

      <Footer />
    </div>
  );
};

export default AttractionDetails;

function DropdownPrimaryCategory({
  categories,
  value,
  onChange,
  error,
}: {
  categories: AttractionCategories[];
  value: string;
  onChange: (newValue: string) => void;
  error: FieldError | undefined;
}) {
  const options = categories
    .map((c) => ({
      value: c.name,
      label: c.name,
    }))
    .sort((a, b) => a.label!.localeCompare(b.label!))
    .filter((v) => v.value)
    .filter((v) => !hiddenCategories.includes(v.label ?? ""));
  return (
    <Select
      components={{
        Option: SingleSelectOption,
        DropdownIndicator: DropDownIndicator,
        IndicatorSeparator: () => null,
      }}
      value={options.find((o) => o.value === value)}
      options={options}
      placeholder="Choose primary category"
      styles={{
        menu: (styles) => ({
          ...styles,
          margin: 0,
          border: "none",
          zIndex: 15,
        }),
        menuList: (base, isSelected) => ({
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
          background: "white",

          "::-webkit-scrollbar": {
            width: 14,
          },
          "::-webkit-scrollbar-thumb": {
            background: "#c2c2c2",
            borderRadius: 10,
            border: "4px solid white",
          },
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            background: "white",
            border: "1px solid #c2c2c2",
            color: isSelected ? "white" : "#555",
            "&:hover": {
              backgroundColor: "#6836D1",
              color: isSelected || isFocused ? "white" : "#555",
              border: isFocused ? "1px solid white" : "",
            },
            "&:active": {
              backgroundColor: "#6836D1",
              color: "white",
            },
          };
        },
        singleValue: (base) => ({
          ...base,
          color: "#555",
          fontSize: 14,
        }),
        control: (styles, state) => ({
          ...styles,
          cursor: "pointer",
          outline: "none",
          border: state.isFocused
            ? "1px solid #CED3CF"
            : `1px solid ${error ? "red" : "#CED3CF"}`,
          "&:hover": {
            border: state.isFocused ? "1px solid #CED3CF" : "1px solid #CED3CF",
          },
          boxShadow: "none",
          paddingTop: 3,
          paddingBottom: 3,
          margin: 0,
          minHeight: 40,
          height: 40,
          borderRadius: 8,
        }),
        placeholder: (styles) => ({
          ...styles,
          fontSize: 14,
          color: "#212121",
          fontWeight: 400,
        }),
        valueContainer: (styles) => ({
          ...styles,
          paddingRight: 20,
          paddingLeft: 20,
          fontSize: 14,
        }),
      }}
      onChange={(v) => {
        if (!v?.value) return;
        onChange(v?.value);
      }}
    />
  );
}

export function DropdownSecondaryCategory({
  categories,
  value,
  onChange,
}: {
  categories: {
    label: string | undefined;
    value: string | undefined;
  }[];
  value: string[];
  onChange: (newValue: string[]) => void;
}) {
  return (
    <Select
      value={value.map((v) => categories.find((c) => c.value === v))}
      onChange={(v) => {
        if (!v) return;
        onChange([...v.map((c) => c?.value ?? "")]);
      }}
      isMulti
      isOptionDisabled={(option) =>
        !value.includes(option?.value ?? "") && value.length >= 3
      }
      isClearable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      options={categories}
      components={{
        Option: MultiSelectOptions,
        ClearIndicator: ClearIndicator,
        DropdownIndicator: DropDownIndicator,
        IndicatorSeparator: () => null,
      }}
      placeholder={"Choose secondary categories"}
      isSearchable={false}
      styles={{
        multiValue: (styles) => ({
          ...styles,
          backgroundColor: "#F1F1F1",
          borderRadius: 8,
          height: 31,
          display: "flex",
          alignItems: "center",
        }),
        multiValueRemove: () => ({
          display: "none",
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: "#000",
          fontSize: 14,
          fontWeight: 400,
          display: "block",
          paddingLeft: 10,
          paddingRight: 10,
        }),
        menu: (styles) => ({
          ...styles,
          margin: 0,
          paddingLeft: 5,
          border: "1px solid #6836D1",
        }),
        menuList: (base) => ({
          ...base,
          marginTop: 0,
          marginBottom: 0,

          "::-webkit-scrollbar": {
            width: 14,
          },
          "::-webkit-scrollbar-thumb": {
            background: "#c2c2c2",
            borderRadius: 10,
            border: "4px solid white",
          },
        }),
        noOptionsMessage: (base) => ({
          ...base,
          color: "#555",
        }),
        control: (styles, state) => ({
          ...styles,
          cursor: "pointer",
          outline: "none",
          border: state.isFocused ? "1px solid #CED3CF" : "1px solid #CED3CF",
          "&:hover": {
            border: state.isFocused ? "1px solid #CED3CF" : "1px solid #CED3CF",
          },
          boxShadow: "none",
          paddingTop: 0,
          paddingRight: 8,
          paddingLeft: 5,
          paddingBottom: 0,
          minHeight: 40,
          borderRadius: 8,
        }),
        placeholder: (styles) => ({
          ...styles,
          fontSize: 14,
          color: "#212121",
          fontWeight: 400,
        }),
      }}
      theme={(theme) => ({
        ...theme,
        background: "none",
        colors: {
          ...theme.colors,
          neutral80: "#555",
        },
      })}
    />
  );
}

function ModalPrimaryCategory({
  categories,
  value,
  onChange,
  errors,
  isSubmitted,
  isLoading,
  triggerValidation,
}: {
  categories: AttractionCategories[];
  value: string;
  onChange: (newValue: string) => void;
  errors: FieldErrors<AttractionDetailsForm>;
  isSubmitted: boolean;
  isLoading: boolean;
  triggerValidation: UseFormTrigger<AttractionDetailsForm>;
}) {
  const [getShowModal, setShowModal] = useState(false);
  const options = categories
    .map((c) => ({
      value: c.name,
      label: c.name,
    }))
    .sort((a, b) => a.label!.localeCompare(b.label!))
    .filter((v) => v.value);

  function showModal() {
    setShowModal(true);
    setSelected(value);
  }

  const [selected, setSelected] = useState(value);
  return (
    <>
      <div className={`flex ${!value ? "flex-col" : "flex-row gap-4"}`}>
        {!!value && (
          <div
            className={`text-sm cursor-pointer flex items-center bg-white text-cs-pink border border-cs-pink font-sm px-4 py-2 w-auto rounded-3xl font-normal ${
              !value ? "mr-auto" : ""
            }`}
            onClick={showModal}
          >
            <label className="checkbox-item white-checkmark">
              <input type="checkbox" checked readOnly />
              <span className="checkmark"></span>
            </label>
            {value}
          </div>
        )}

        {!value ? (
          <button
            type="button"
            onClick={showModal}
            className={`mr-auto text-sm leading-none rounded-full h-cs-55 w-full py-2 px-12 text-white border-cs-1 font-bold shadow-cs-button-backdrop hover:bg-white hover:text-cs-pink hover:outline-cs-pink md:w-auto ${
              isSubmitted && errors?.primaryCategory
                ? "bg-cs-red border-cs-red hover:bg-white"
                : isLoading
                ? "bg-cs-bright-lt-gray border-cs-bright-lt-gray text-opacity-50 hover:bg-cs-bright-lt-gray hover:border-cs-bright-lt-gray hover:text-opacity-50 hover:text-white"
                : "bg-cs-pink border-cs-pink"
            }`}
          >
            Choose primary category
          </button>
        ) : (
          <></>
        )}
      </div>

      <ModalWrapper
        open={getShowModal}
        setOpen={setShowModal}
        className={`w-[787px]`}
        closeOnOverlay={false}
        onClose={() => setSelected(value)}
      >
        <h3 className="text-2xl font-bold">Select your primary category</h3>
        <p className="font-normal my-3 text-base text-cs-gray">
          Choose the most relevant category for your attraction
        </p>

        <MultiButtonSelect
          options={options}
          value={selected}
          onChange={(v) => {
            if (!v) return;
            setSelected(v as string);
          }}
        />

        {errors.primaryCategory && (
          <div className="text-cs-red flex items-center mt-auto mb-0 pt-10">
            <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
            <span className="flex-1">{errors.primaryCategory?.message}</span>
          </div>
        )}

        <div className="flex gap-2 justify-start mt-auto pt-10">
          <OutlineButton
            scale="sm"
            type="button"
            onClick={() => {
              setShowModal(false);
              setSelected(value);
            }}
          >
            Cancel
          </OutlineButton>

          <PrimaryButton
            scale="sm"
            type="button"
            onClick={async (e) => {
              const result = !selected
                ? await triggerValidation("primaryCategory")
                : true;
              if (!result) return;
              onChange(selected);
              setShowModal(false);
              await triggerValidation("primaryCategory");
            }}
          >
            Confirm
          </PrimaryButton>
        </div>
      </ModalWrapper>
    </>
  );
}

function ModalSecondaryCategory({
  categories,
  value,
  onChange,
  errors,
  isSubmitted,
  isLoading,
  triggerValidation,
}: {
  categories: AttractionCategories[];
  value: string[];
  onChange: (newValue: string[]) => void;
  errors: FieldErrors<AttractionDetailsForm>;
  isSubmitted: boolean;
  isLoading: boolean;
  triggerValidation: UseFormTrigger<AttractionDetailsForm>;
}) {
  const [getShowModal, setShowModal] = useState(false);
  const options = categories
    .map((c) => ({
      value: c.name,
      label: c.name,
    }))
    .sort((a, b) => a.label!.localeCompare(b.label!))
    .filter((v) => v.value);

  function showModal() {
    setShowModal(true);
    setSelected(value);
  }

  const [selected, setSelected] = useState(value);
  return (
    <>
      <div className={`flex flex-row gap-4 flex-wrap`}>
        <>
          {value?.map((category) => {
            return (
              <div className="w-auto flex">
                <div
                  className={`text-sm whitespace-nowrap cursor-pointer flex items-center bg-white text-cs-pink border border-cs-pink font-sm px-4 py-2 w-auto rounded-3xl font-normal my-1`}
                  onClick={showModal}
                >
                  <label className="checkbox-item white-checkmark">
                    <input type="checkbox" checked readOnly />
                    <span className="checkmark"></span>
                  </label>
                  {category}
                </div>
              </div>
            );
          })}
        </>

        {value.length === 0 ? (
          <button
            type="button"
            onClick={showModal}
            className={`mr-auto leading-none rounded-full h-cs-55 w-full py-2 px-2 sm:px-12 mt-3 text-white text-sm border-cs-1 font-bold shadow-cs-button-backdrop hover:bg-white hover:text-cs-pink hover:outline-cs-pink md:w-auto ${
              isSubmitted && errors.secondaryCategoryOptions
                ? "bg-cs-red border-cs-red"
                : isLoading
                ? "bg-cs-bright-lt-gray border-cs-bright-lt-gray text-opacity-50 hover:bg-cs-bright-lt-gray hover:border-cs-bright-lt-gray hover:text-opacity-50 hover:text-white"
                : "bg-cs-pink border-cs-pink"
            }`}
          >
            Choose secondary categories
          </button>
        ) : (
          <></>
        )}
      </div>

      <ModalWrapper
        open={getShowModal}
        setOpen={setShowModal}
        className={`w-[787px]`}
        closeOnOverlay={false}
        onClose={() => setSelected(value)}
      >
        <h3 className="text-2xl font-bold text-cs-gray">
          Select your secondary category
        </h3>
        <p className="font-normal my-3 text-base text-cs-gray">
          Choose up to 3 secondary categories *
        </p>

        <MultiButtonSelect
          options={options}
          value={selected}
          onChange={(v) => {
            if (!v) return;
            setSelected(v as string[]);
          }}
          isMultiple={true}
          max={3}
        />
        {errors.secondaryCategory && (
          <div className="text-cs-red flex items-center mt-auto mb-0 pt-10">
            <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
            <span className="flex-1">{errors.secondaryCategory?.message}</span>
          </div>
        )}
        <div className="flex gap-2 justify-start mt-auto pt-10">
          <OutlineButton
            scale="sm"
            type="button"
            onClick={() => {
              setShowModal(false);
              setSelected(value);
            }}
          >
            Cancel
          </OutlineButton>

          <PrimaryButton
            scale="sm"
            type="button"
            onClick={async (e) => {
              const result = !selected
                ? await triggerValidation("primaryCategory")
                : true;
              if (!result) return;
              onChange(selected);
              setShowModal(false);
              await triggerValidation("primaryCategory");
            }}
          >
            Confirm
          </PrimaryButton>
        </div>
      </ModalWrapper>
    </>
  );
}

function MultiButtonSelect({
  options,
  value,
  onChange,
  isMultiple = false,
  max = 3,
}: {
  options: {
    label?: string;
    value?: string;
  }[];
  value: string | string[];
  onChange: (newVal: string | string[]) => void;
  isMultiple?: boolean;
  max?: number;
}) {
  return (
    <>
      <div className="gap-3 flex flex-wrap h-[300px] overflow-y-auto px-5">
        {options.map((option) => {
          const isSelected =
            isMultiple && Array.isArray(value)
              ? value.includes(option.value ?? "")
              : value === option.value;
          const isDisabled =
            Array.isArray(value) && value.length >= max && !isSelected;
          return (
            <MultiSelectButton
              key={option.value}
              value={option.label ?? ""}
              isDisabled={isDisabled}
              isSelected={isSelected}
              onClick={() => {
                if (isDisabled) return;
                let newVal;

                if (
                  Array.isArray(value) &&
                  value.includes(option.value ?? "")
                ) {
                  newVal = value.filter((v) => v !== (option.value ?? ""));
                } else if (Array.isArray(value)) {
                  newVal = [...(value as string[]), option.value ?? ""];
                } else {
                  newVal = option.value ?? "";
                }
                onChange(newVal);
              }}
            />
          );
        })}
      </div>
    </>
  );
}

function MultiSelectButton({
  isSelected,
  value,
  isDisabled,
  onClick,
}: {
  isSelected: boolean;
  value: string;
  isDisabled: boolean;
  onClick: () => void;
}) {
  return (
    <button type="button" className="cursor-pointer" onClick={() => onClick()}>
      <label
        className={`checkbox-item cursor-pointer white-checkmark w-full h-10 rounded-full px-8 text-sm font-normal flex items-center border-2 ${
          isSelected
            ? "text-cs-pink border-cs-pink"
            : "border-[#CFDBD5] text-cs-gray"
        } ${isDisabled ? "bg-gray-100 opacity-80" : ""}`}
      >
        <input
          className={`${
            isSelected ? "default-selected" : ""
          } !w-full !h-10 cursor-pointer`}
          type="checkbox"
          checked={!!isSelected}
          readOnly
        />
        <span className="checkmark border-none top-2/4 -translate-y-2/4 left-3"></span>
        {value}
      </label>
    </button>
  );
}

function ShortDescription({
  isStepperActive,
  control,
  errors,
  voImprovement,
}: {
  isStepperActive: boolean;
  control: any;
  errors: string | null | undefined;
  voImprovement: ReactNode;
}) {
  return (
    <div
      className={`flex flex-col w-full md:w-3/4 ${
        isStepperActive ? "xl:w-[56%]" : "xl:w-1/2"
      }`}
    >
      <div className={`flex flex-col gap-2.5`}>
        <span className="flex items-center font-bold text-sm">
          Short description {!isStepperActive && voImprovement}{" "}
          <span className="text-cs-pink">*</span>
        </span>
        <p className="text-sm text-[#5F646D] font-normal flex w-full">
          In 20 words or less, give us an overview of your attraction. Be
          concise and exciting, highlighting main features that would interest
          our users. We'll use this quick overview to suggest relevant days out
          on our website, social media and email.
        </p>
      </div>
      <div className="relative flex flex-col gap-2.5">
        <Controller
          control={control}
          name={"summary"}
          render={({ field: { ref, value, onChange } }) => (
            <>
              <textarea
                value={value ?? ""}
                onChange={onChange}
                placeholder="Spotlight the key elements of your attraction within the short description. This enables us to recommend your attraction across various platforms!"
                className="text-cs-gray placeholder:text-sm text-md p-3 w-full mt-2 border-[#CFDBD5] rounded-lg border-cs-1 outline-cs-pink h-40"
              ></textarea>
              <p className="text-[#5F646D] text-sm text-right w-full mt-2">
                {(value?.split(" ")?.length ?? 0) + " / 20"} words
              </p>
            </>
          )}
        />

        {isStepperActive && (
          <PointingArrowHintSm
            className="-top-2 -right-[420px]"
            boxClassName="w-[333px]"
          >
            <div>Make sure that you:</div>
            <ul className="list-disc pl-4">
              <li>
                Your content is original, not copy and pasted from another
                website
              </li>
              <li>
                You include enough information to stand out against competitors
              </li>
              <li>Address the needs and interests of families</li>
            </ul>
          </PointingArrowHintSm>
        )}
      </div>

      {errors && <ErrorMessage>{errors}</ErrorMessage>}
    </div>
  );
}

function LongDescription({
  isStepperActive,
  voImprovement,
  initialValue,
  errors,
  control,
  getValues,
  setValue,
}: {
  isStepperActive: boolean;
  voImprovement: ReactNode;
  initialValue: string | undefined;
  errors: string | null | undefined;
  control: any;
  getValues: any;
  setValue: any;
}) {
  return (
    <div
      className={`flex flex-col w-full md:w-3/4 mt-5 ${
        isStepperActive ? "xl:w-[56%]" : "xl:w-1/2"
      }`}
    >
      <div className={`flex flex-col gap-2.5`}>
        <div className="font-bold text-sm">
          Long description {!isStepperActive && voImprovement}{" "}
          <span className="text-cs-pink">*</span>
        </div>

        <p className="text-sm text-[#5F646D] font-normal flex w-full">
          This section is the most important information on your listing page.
          Make sure it's unique content (don’t copy and paste from anywhere
          else). Try and write as much information as you can here. Include info
          on everything from rides / activities to entertainment and games. You
          have up to 1,000 available so go for it!
        </p>
      </div>

      <div className="relative">
        {initialValue !== undefined && (
          <RichTextEditor
            charLabel={``}
            isWordCount={true}
            maxChars={1000}
            maxCharSuffix={" words"}
            initialValue={initialValue ?? ""}
            onChange={(value: string) => setValue("descriptionHtml", value)}
            onDirty={() =>
              setTimeout(() => {
                setValue("descriptionHtml", getValues("descriptionHtml"), {
                  shouldDirty: true,
                });
              }, 100)
            }
            placeholder="Highlight all the distinct features and attractions your venue offers, including anything new or upcoming. Emphasise the overall experience and atmosphere visitors can expect during their visit, who is your attraction perfect for?"
            label=""
          />
        )}
        {isStepperActive && (
          <PointingArrowHintSm
            className="-top-16 -right-[420px]"
            boxClassName="w-[333px]"
          >
            <div>Make sure that you:</div>
            <ul className="list-disc pl-4">
              <li>Include your attraction name more than 3 times</li>
              <li>Explain what makes your attraction stand out to others</li>
              <li>Add any new information or events that are coming up!</li>
              <li>
                Include phrases like “you will love this attraction if...”,
                “this attraction is perfect for...”
              </li>
            </ul>
          </PointingArrowHintSm>
        )}
      </div>

      {errors && <ErrorMessage>{errors}</ErrorMessage>}
    </div>
  );
}
