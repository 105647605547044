import { components } from "react-select";

const Options: React.FC = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  innerRef,
  ref,
  value,
  defaultValue,
  selectProps,
  ...rest
}: any) => {
  const style = {
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    color: "#555",
    display: "flex",
    fontSize: 14,
    fontWeight: 400,
    height: 40,
    padding: 0,
    flexWrap: "wrap",
    width: "auto",
    borderRadius: 40
  };

  const props = {
    ...innerProps,
    style
  };

  return (
    <components.Option
    {...rest}
    isDisabled={isDisabled}
    isFocused={isFocused}
    isSelected={isSelected}
    getStyles={getStyles}
    innerProps={props}
    innerRef={innerRef}
    >
      <label className={`checkbox-item white-checkmark w-full h-10 rounded-full px-5 text-sm font-normal flex items-center border ${isSelected || value == true || value && value.includes("true") ? "text-cs-pink border-cs-pink" : "border-[#CFDBD5] text-cs-gray"} ${isDisabled ? 'bg-gray-100 opacity-80' : ''}`}>
        <input className={`${ selectProps.customDefaultValue && selectProps.customDefaultValue.filter((option: any) => option?.value == value).length > 0 ? "default-selected" : ""} !w-full !h-10 `} type="checkbox" checked={isSelected || value == true ? true : false || value && value.includes("true") ? true : false} readOnly/>
        <span>
          {children}
        </span>
       
      </label>
    </components.Option>
  );
};

export default Options;