import { useEffect } from "react";
import { apiSlice } from "../../store/apiSlice";
import { useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";

type BadgeTypes = "FAMILY_FRIENDLY" | "REVIEW_US" | "APPROVED";

const notifications: {
  id: BadgeTypes;
  title: string;
  caption: string;
  list: {
    title: string;
    text: string;
  }[];
  badge: string;
}[] = [
  {
    id: "FAMILY_FRIENDLY",
    title:
      "You’ve Won An Award! Claim now to boost your Venue Optimisation Score by 10+ points  🎉",
    caption:
      "Did you know that 74% of UK parents are more likely to trust an attraction they see on DOWTK?",
    list: [
      {
        title: "",
        text: "Display your award badge on your website with a link to your attraction page",
      },
      {
        title: "",
        text: "Earn 10+ Venue Optimisation Score points to increase your visibility across the site and help more families discover your attraction!",
      },
    ],
    badge: "/assets/amp/badges/Family-light.svg",
  },
  {
    id: "REVIEW_US",
    title: "Action Needed: Missing Reviews! 🚨",
    caption:
      "Did you know that 74% of UK parents are more likely to trust an attraction they see on DOWTK?",
    list: [
      {
        title: "What do do: ",
        text: "Add a “Leave a Review” badge to your website with a link to your listing",
      },
      {
        title: "Why it matters: ",
        text: "Displaying the badge earns you 10+ Venue Optimisation Score points, which increases your visibility across the site. A higher score also helps more families discover and trust your attraction",
      },
      {
        title: "Get started: ",
        text: "Download your badge today and watch your reviews grow!",
      },
    ],
    badge: "/assets/amp/badges/AwardBadge-light.svg",
  },
  {
    id: "APPROVED",
    title:
      "Your attraction is now DOWTK Approved!  Download Your Badge Now  🎉️",
    caption:
      "Did you know that 74% of UK parents are more likely to trust an attraction they see on DOWTK?",
    list: [
      {
        title: "",
        text: "Add your DOWTK Approved badge to your homepage with a link to your attraction page",
      },
      {
        title: "",
        text: "Reassure families that your attraction is a trusted choice for their day out",
      },
      {
        title: "",
        text: "Improve your Venue Optimisation Score to increase your visibility across the site and attract more visitors",
      },
    ],
    badge: "/assets/amp/badges/Approved-light.svg",
  },
];

export function BadgeNotification({ type, shortId }: { type: BadgeTypes, shortId: string }) {
  const badge = notifications.find((n) => n.id === type)!;
  const { user } = useAppSelector((state) => state.global);

  const [seenApprovedBadge] = apiSlice.useUserSeenApprovedBadgeMutation();
  const [seenBadge] = apiSlice.useAttractionSeenBadgeMutation();
  useEffect(() => {
    (async () => {
      if (type === "APPROVED" && !user?.seen_approved_badge) {
        await seenApprovedBadge();
      }
    })();
  }, [type, user, seenApprovedBadge]);

  const navigate = useNavigate();
  return (
    <div className="mb-5 flex flex-wrap lg:flex-nowrap gap-5 xl:mx-[70px]">
      <div className="flex gap-[32px] border-l-2 border-[#EFCBCB] border border-l-[#D21D1D] rounded-r-[4px] bg-[#F4ECF1] p-4 max-w-[928px]">
        <div>
          <div className="text-[#D21D1D] text-[10px] font-normal flex items-center gap-1">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.625 6C0.625 3.03147 3.03147 0.625 6 0.625C8.96855 0.625 11.375 3.03147 11.375 6C11.375 8.96855 8.96855 11.375 6 11.375C3.03147 11.375 0.625 8.96855 0.625 6ZM6 3.75C6.27615 3.75 6.5 3.97386 6.5 4.25V6.25C6.5 6.52615 6.27615 6.75 6 6.75C5.72385 6.75 5.5 6.52615 5.5 6.25V4.25C5.5 3.97386 5.72385 3.75 6 3.75ZM6 7.25C5.72385 7.25 5.5 7.47385 5.5 7.75C5.5 8.02615 5.72385 8.25 6 8.25H6.0045C6.28065 8.25 6.5045 8.02615 6.5045 7.75C6.5045 7.47385 6.28065 7.25 6.0045 7.25H6Z"
                fill="#D21D1D"
              />
            </svg>
            Important Action Point
          </div>

          <div className="text-[#121110] text-base font-medium">
            {badge.title}
          </div>

          <div className="mt-2 text-[#2F2D2C] text-sm font-normal">
            {badge.caption}
          </div>

          <div className="mt-2 text-black font-normal text-sm flex flex-col gap-1">
            {badge.list.map((l) => (
              <div key={JSON.stringify(l)} className="flex items-center gap-2">
                <svg
                  className="flex-shrink-0"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.83335 15C3.87531 15 0.666687 11.7914 0.666687 7.83334C0.666687 3.8753 3.87531 0.666672 7.83335 0.666672C11.7914 0.666672 15 3.8753 15 7.83334C15 11.7914 11.7914 15 7.83335 15ZM11.0122 6.26012C11.2479 5.97727 11.2097 5.5569 10.9268 5.32118C10.644 5.08548 10.2236 5.12369 9.98789 5.40654L7.12189 8.84574L5.63809 7.36194C5.37774 7.1016 4.95563 7.1016 4.69528 7.36194C4.43493 7.62227 4.43493 8.0444 4.69528 8.30474L6.69529 10.3047C6.82775 10.4372 7.00975 10.5078 7.19689 10.4993C7.38402 10.4908 7.55895 10.404 7.67882 10.2601L11.0122 6.26012Z"
                    fill="black"
                  />
                </svg>
                <div>
                  {l.title && <strong className="font-bold">{l.title}</strong>}
                  {l.text}
                </div>
              </div>
            ))}
          </div>

          <button
            type="button"
            className={`bg-cs-pink border border-cs-pink w-[181px] h-[30px] flex justify-center items-center rounded-lg text-white font-normal text-xs mt-3`}
            onClick={async () => {
              await seenBadge({ shortId, type });
              navigate(`/attractions/${shortId}/awards-badges`)
            }}
          >
            View Badge & Instructions
          </button>
        </div>

        <div className="flex-shrink-0">
          <img src={badge.badge} className="w-[120px]" alt="Award Badge" />
        </div>
      </div>
    </div>
  );
}
