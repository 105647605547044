import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { ReactNode, useEffect, useState } from "react";
import { apiSlice } from "../../../store/apiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { VenueOptScoreProgressCircular } from "../../../components/amp/VenueOptScoreProgressCircular";
import { useAppDispatch, useAppSelector } from "../../../store";
import moment from "moment";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import TextInput from "../../../components/form/TextInput";
import { globalActions } from "../../../store/globalSlice";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import ModalWrapper from "../../../components/global/ModalWrapper";
import { PrimaryButton } from "../../../components/global/PrimaryButton";
import { OutlineButton } from "../../../components/global/OutlineButton";
import AttractionDetails from "./AttractionDetails";

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

const steps: {
  title: string;
  points?: number;
  desc: string;
}[] = [
  {
    title: "Add a Link to Your Listing Page",
    points: 10,
    desc: "Include a direct link to your dedicated listing page on Day Out With The Kids on your homepage. ",
  },
  {
    title: "Receive 10+ Visibility Optimisation (VO) Points ",
    desc: "Boost your ranking within our platform by earning 10+ VO points when you display the badge and link to your listing. Higher VO points mean improved search visibility, helping more families discover your attraction. ",
  },
  {
    title: "Increase Your Visibility on Day Out With The Kids",
    desc: "With the badge in place, you’ll improve your visibility across the site. You’ll attract more visitors, and gain more exposure. It’s a simple step to stand out and maximise your reach to our growing audience of families looking for their next adventure.",
  },
];

const AttractionAwardsAndBadges: React.FC<IAttractionDetails> = () => {
  const dispatch = useAppDispatch();
  const { toastNotification } = useAppSelector((state) => state.global);

  const { attractionId } = useParams();
  const {
    data: getAttraction,
    error: attractionError,
    isSuccess: isGetSuccess,
    isFetching,
    refetch
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  const urlVerified = getAttraction?.result?.published_version?.badge_url_verified_at;

  const { result } = getAttraction ?? {};

  useEffect(() => {
    document.title = "Attraction Awards and Badges | Day Out With The Kids";
  }, []);

  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.global);

  const [submitBadgeUrl] = apiSlice.useSubmitBadgeUrlMutation();
  const [verifyBadgeUrl, {isLoading: isVerifyingBadge}] = apiSlice.useToggleVerifyBadgeUrlMutation();
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 404
    ) {
      navigate("/not-found", { replace: true });
    }
  }, [attractionError, navigate]);

  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<{}> = async (e) => {
      return true;
    };

    return fn;
  };

  const [submittedModal, setSubmittedModal] = useState(false);
  const submitURL = async (url: string) => {
    const result = await submitBadgeUrl({
      shortId: attractionId || "",
      url: url as string,
    });

    if ("data" in result) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Success",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );

      restartAnimation();
      setSubmittedModal(true);
    } else {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Something went wrong.",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );

      restartAnimation();
    }
  }
  const submit = async (e: any) => {
    await submitURL(e.url as string);
  };

  const verifyBadge = async () => {
    if (user?.role !== 'ADMIN') return;


    const result = await verifyBadgeUrl({shortId: attractionId || ""});

    if ("data" in result) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Success",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );

      restartAnimation();
    } else {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Something went wrong.",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );

      restartAnimation();
    }
  };

  const schema = z.object({
    url: z.string().url(),
  });

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (getAttraction && isGetSuccess && !isFetching) {
      form.setValue(
        "url",
        (getAttraction?.result.published_version.badge_url ?? "").trim()
      );

      form.reset(
        {
          ...form.getValues(),
        },
        {
          keepDirty: false,
        }
      );
    }
  }, [getAttraction, isFetching]);

  const isFamilyFav = getAttraction?.result?.published_version?.is_family_fav;
  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Awards & Badges"
        mobilePublishButtons={true}
        showPublishingButtons
        getPayload={() => ({})}
        saveFn={onSubmit}
      />

      <div className="h-0 xl:h-[109px]"></div>
      <Container>
        <h2 className="text-[22px] font-extrabold text-[#212121] leading-[30px]">
          Download your badge to boost your attraction’s visibility!
        </h2>

        <p className="font-normal text-sm text-[#5F646D] mt-1 leading-[21px]">
          Adding a badge to your website’s homepage is a simple way to boost
          your Venue Optimisation Score and attract more visitors. Showcasing
          your trusted status helps build credibility and improves your
          listing’s visibility across the site. Download your badge below and
          start improving your attraction’s visibility today.
        </p>

        <div className="mt-4 border-l-2 border-l-[#6836D1] border border-[#E8E8E4] p-4">
          <ul className="flex flex-col gap-4 pl-2 border-l border-[#EEE7FD] pt-1">
            {steps.map((step, i) => (
              <li className="relative">
                <div
                  className={`w-[7px] h-[7px] bg-[#EEE7FD] rounded-full left-[-12px] top-[3px] absolute flex justify-center items-center ${
                    i === 0 ? "translate-y-[-4px]" : ""
                  }`}
                >
                  <div className="w-[6px] h-[6px] bg-cs-pink rounded-full"></div>
                </div>
                <div className="text-xs font-bold text-[#121110]">
                  {step.title}{" "}
                  {step.points && (
                    <span className="text-cs-pink font-normal">
                      (Adds {step.points}pts)
                    </span>
                  )}
                </div>
                <div className="mt-1 text-[#5F646D] text-sm font-normal">
                  {step.desc}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>

      <Container>
        <h2 className="text-[22px] font-extrabold text-[#212121] leading-[30px]">
          Your Current Venue Optimisation Score
        </h2>

        <p className="font-normal text-sm text-[#5F646D] mt-1 leading-[21px]">
          Compare your current Venue Optimisation Score to your competitors. Add
          your badge to your homepage to watch your score improve as you boost
          your visibility!
        </p>

        <div className="mt-4 p-4 rounded-lg border border-[#550FEB1A]">
          <div className="flex items-center gap-2">
            <VenueOptScoreProgressCircular
              score={result?.published_version?.total_vo_score || 0}
              maxScore={result?.published_version?.max_possible_vo_score || 100}
              size={85}
              stroke={9}
            >
              <div className="flex flex-col items-center text-lg font-bold scale-[0.85]">
                <div>{result?.published_version?.total_vo_score || 0}</div>
                <div className="h-[1px] bg-black w-8"></div>
                <div>
                  {result?.published_version?.max_possible_vo_score || 100}
                </div>
              </div>
            </VenueOptScoreProgressCircular>

            <div>
              <div className="text-[#212121] text-lg font-bold">Your Score</div>
              <div className="text-sm font-normal mt-1 text-[#5F646D]">
                Last updated{" "}
                {moment(result?.published_version?.optimisation_scores?.[0]?.updated_at || result?.updated_at).format("DD MMM YYYY")}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <h2 className="text-[22px] font-extrabold text-[#212121] leading-[30px]">
          Add your Day Out With The Kids badge in 3 easy steps!
        </h2>

        <div className="font-normal text-sm text-[#5F646D] mt-1 leading-[21px]">
          <div>
            1. Click “Download Assets” to get a folder with different image
            formats. Choose the one that works best for you.
          </div>
          <div>2. Add the image to your site</div>
          <div>3. Copy and paste your homepage URL into the box below</div>
          <br />
          Once your homepage has been verified by us - we will allocate 10x
          Venue Optimisation points to your attraction!
        </div>

        <div className="mt-4">
          <div className="text-[#212121] font-bold text-base">
            Your Badge Asset
          </div>

          <div className="mt-2 grid grid-cols-2 gap-4 max-w-[880px]">
            <div className="p-4 rounded-lg border border-[#550FEB1A] flex justify-center items-center min-h-[168px] gap-10">
              <img
                width={!isFamilyFav ? 120 : 80}
                src="/assets/amp/badges/Approved-light.svg"
                alt=""
              />
              {isFamilyFav && <img
                width={80}
                src="/assets/amp/badges/Family-light.svg"
                alt=""
              />}
              <img
                width={!isFamilyFav ? 120 : 80}
                src="/assets/amp/badges/AwardBadge-light.svg"
                alt=""
              /> 
            </div>
            <div className="p-4 rounded-lg border border-[#212121] bg-[#212121] flex justify-center items-center min-h-[168px] gap-10">
              <img
                width={!isFamilyFav ? 120 : 80}
                src="/assets/amp/badges/Approved-dark.svg"
                alt=""
              />
              {isFamilyFav && <img
                width={80}
                src="/assets/amp/badges/Family-dark.svg"
                alt=""
              />}
              <img
                width={!isFamilyFav ? 120 : 80}
                src="/assets/amp/badges/AwardBadge-dark.svg"
                alt=""
              /> 
            </div>
            <div className="col-span-2">
              <a
                href={`/assets/amp/badges/Badges${isFamilyFav ? '' : '-1'}.zip`}
                download={true}
                className={`bg-cs-pink border border-cs-pink w-[131px] h-[30px] flex justify-center items-center rounded-lg text-white font-normal text-xs ml-auto`}
              >
                Download Assets
              </a>
            </div>
          </div>
        </div>

        <div className="mt-4 max-w-[880px]">
          <div className="text-[#212121] font-bold text-base">
            URL of Award/Badge Placement
          </div>
          <div className="font-normal text-sm text-[#5F646D] mt-1 leading-[21px]">
            Please copy and paste the full URL of the page where the Day Out
            With The Kids badge is displayed. This helps us confirm its
            placement so you can get your VO score boost.
          </div>
        </div>

        <form
          className="w-full h-full flex flex-col max-w-[880px]"
          onSubmit={form.handleSubmit(submit)}
        >
          <div className="relative w-[400px]">
          <TextInput
            name={"url"}
            className="w-[400px] max-w-full text-sm text-cs-gray mt-2"
            labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
            inputClassName="border-cs-1 border-[#DEE5E2] text-cs-gray rounded-md py-3 pr-4 pl-4 flex flex-col w-full focus:outline-cs-pink h-[40px]"
            label=""
            placeholder="https://dayoutwiththekids.co.uk/"
            control={form.control}
            required
            readonly={!!urlVerified}
            inputProps={{
              ...form.register("url"),
              type: "text",
            }}
          />
            <button disabled={isVerifyingBadge || user?.role !== 'ADMIN'} onClick={async () => {
              const url = form.getValues('url') as string
              const validated = await form.trigger('url');
              if (!url || !validated) {
                return;
              }
              await submitURL(form.getValues('url') as string);
              setSubmittedModal(false);
              await verifyBadge();

              setTimeout(async () => {
                await refetch();
              }, 1000)
              }} type="button" className={`absolute right-4 -translate-y-1/2 ${form.formState.errors.url?.message ? 'top-[calc(50%-7px)]' : 'top-[calc(50%+4px)]'} rounded-[4px] h-[23px] w-[75px] flex items-center justify-center text-xs ${!getAttraction?.result?.published_version?.badge_url_verified_at ? 'bg-[#F48F7A] text-white' : 'bg-[#048B4A] text-white'}`}>{!getAttraction?.result?.published_version?.badge_url_verified_at ? "Unverified" : "Verified"}</button>
          </div>

          <button
            type="submit"
            disabled={form.formState.isSubmitting || !!urlVerified}
            className={`${
              form.formState.isSubmitting || urlVerified
                ? "bg-[#BBB7C6] border border-[#BBB7C6] text-[#6F6B79]"
                : "bg-cs-pink border border-cs-pink text-white"
            } w-[100px] h-[30px] flex justify-center items-center rounded-lg font-normal text-xs ml-auto`}
          >
            Submit URL
          </button>
        </form>
      </Container>

      <div className="h-12"></div>
      <ModalWrapper
        className="w-[95%] md:w-[500px]"
        open={submittedModal}
        setOpen={setSubmittedModal}
        closeOnOverlay={false}
        hideCloseButton={true}
        customContainerClassName={`relative overflow-y-hidden flex flex-col w-full bg-white rounded-lg overflow-y-auto overflow-x-hidden pt-5`}
      >
        <div className="relative">
          <button
            type="button"
            className="absolute top-0 right-4"
            onClick={() => setSubmittedModal(false)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4.38462C7.79414 4.38462 4.38462 7.79414 4.38462 12C4.38462 16.2059 7.79414 19.6154 12 19.6154C16.2059 19.6154 19.6154 16.2059 19.6154 12C19.6154 7.79414 16.2059 4.38462 12 4.38462ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM9.01817 9.01816C9.28853 8.74779 9.72688 8.74779 9.99724 9.01816L12 11.0209L14.0028 9.01816C14.2732 8.74779 14.7115 8.74779 14.9819 9.01816C15.2522 9.28852 15.2522 9.72686 14.9819 9.99723L12.9791 12L14.9819 14.0028C15.2522 14.2731 15.2522 14.7115 14.9819 14.9818C14.7115 15.2522 14.2732 15.2522 14.0028 14.9818L12 12.9791L9.99724 14.9818C9.72688 15.2522 9.28853 15.2522 9.01817 14.9818C8.74781 14.7115 8.74781 14.2731 9.01817 14.0028L11.0209 12L9.01817 9.99723C8.74781 9.72686 8.74781 9.28852 9.01817 9.01816Z"
                fill="black"
              />
            </svg>
          </button>

          <div className="font-light pb-4 px-4">URL of Placement</div>

          <h1 className="text-lg text-black font-bold pt-4 border-t border-[#F3F3F4] px-4">
            Thanks for sending your url for approval!
          </h1>
          <p className="mt-3 text-[#5F646D] text-sm leading-[21px] font-normal px-4">
            Once our team verifies that the badge has been added, your VO score
            will be updated automatically, and you’ll receive a confirmation
            email.
          </p>

          <div className="flex flex-row mt-4 pb-5 px-4 justify-end">
            <OutlineButton
              className="w-[64px]"
              onClick={() => {
                setSubmittedModal(false);
              }}
              scale="sm"
            >
              Close
            </OutlineButton>
          </div>
        </div>
      </ModalWrapper>
      <Footer />
    </div>
  );
};

export default AttractionAwardsAndBadges;

function Container({ children }: { children: ReactNode }) {
  return (
    <DashboardContainer>
      <section
        className={`flex flex-col  xl:mx-[70px] p-6 bg-white xl:rounded-lg`}
      >
        {children}
      </section>
    </DashboardContainer>
  );
}

export function ProgressBar({ percent }: { percent: number }) {
  return (
    <div className="h-[9px] w-full bg-[#D9D9D9] rounded-full relative">
      <div
        className="h-[9px] rounded-full absolute left-0 top-0 bg-[#6836D1]"
        style={{
          width: `${percent > 100 ? 100 : percent}%`,
        }}
      ></div>
    </div>
  );
}
