import React, { useEffect, useState } from "react";
import Creators from "../Creators";
import Diaries from "../Diaries";

const FcnDiaries: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>("Diaries");
    useEffect(() => {
      document.title = "Family Creator Network | Day Out With The Kids";
    }, []);
    return(
        <>
          {
            activeTab === "Diaries" ? <Diaries activeTab={activeTab} setActiveTab={setActiveTab}/> : <Creators activeTab={activeTab} setActiveTab={setActiveTab}/>
          }
        </>
    )
}

export default FcnDiaries;