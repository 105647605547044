import Header from "../../components/global/amp/Header";
import Footer from "../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useForm,
  Controller,
  useFieldArray,
  UseFieldArrayReturn,
  UseFormGetValues,
} from "react-hook-form";
import TextInput from "../../components/form/TextInput";
import { apiSlice } from "../../store/apiSlice";
import { globalActions } from "../../store/globalSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import type { AmpUserDetail } from "../../types/user";
import { useNavigate, useParams } from "react-router-dom";
import { SingleAttractionTemplate } from "../../components/global/SingleAttractionTemplate";
import { PrimaryButton } from "../../components/global/PrimaryButton";
import { ErrorMessage } from "../../components/global/ErrorMessage";
import ModalWrapper from "../../components/global/ModalWrapper";
import { AttractionSearch } from "../../components/form/AttractionsSearch";
import RichTextEditor, {
  getCharLength,
  getWordLength,
} from "../../components/form/RichTextEditor";
import { restartAnimation } from "../../components/global/amp/ToastNotification";
import { hasUrl } from "../../helpers/hasUrl";
import {
  fileToDataString,
  ImageCropper,
  useMediaUpload,
  mediaSchema,
  fetchFileData,
} from "../../hooks/useMediaUploadFile";
import { isSuccessResponse } from "../../helpers/rtkHelpers";

const richTextValidator = z.string().superRefine((value, ctx) => {
  const charLen = getCharLength(value?.trim() ?? "");
  if (charLen === 0 || value.trim().length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Required",
    });
  }

  if (hasUrl(value ?? "")) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `You are not allowed include a url.`,
    });
  }

  const wordLength = getWordLength(value ?? "");
  const maxCharCount = 1000;

  if (wordLength > maxCharCount) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: ``,
    });
  }
});
const AddPdp: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { user, toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const {
    data: getQuery,
    isSuccess: isQuerySuccess,
    isFetching,
    refetch,
  } = apiSlice.useGetPdpQuery({ id: id ?? "" }, { skip: !isEdit || !id });

  const [uploadMedia] = apiSlice.useUploadImageToPdpMutation();
  const [addPdp, { isLoading, isError, isSuccess, error }] =
    apiSlice.useAddPdpMutation();

  const schema = z.object({
    venue: z.string(),
    overview: richTextValidator,
    h1: z.string().optional(),
    page_title: z.string().optional(),
    meta_description: z.string().optional(),
    selections: z
      .object({
        title: z.string().min(1, { message: "Required" }),
        body: z.string(),
        image: z.any()
      })
      .array(),
    included: z.object({
      body: z.string(),
      included: z
        .object({
          value: z.string(),
        })
        .array(),
      excluded: z
        .object({
          value: z.string(),
        })
        .array(),
    }),
    importantInfo: richTextValidator,
    faqs: z
      .object({
        title: z.string().min(1),
        content: z.string(),
      })
      .array()
      .min(1),
    media: mediaSchema,
  });

  type AddPdpForm = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    watch,
    control,
    getValues,
    setValue,
    reset,
  } = useForm<AddPdpForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      included: {
        included: [
          {
            value: "",
          },
        ],
        excluded: [
          {
            value: "",
          },
        ],
      },
      faqs: [
        {
          title: "How do I receive my tickets?",
          content: "",
        },
        {
          title: "What is your cancellation policy?",
          content: "",
        },
        {
          title: "Can I change the date or time on my tickets",
          content: ""
        }
      ],
      selections: [
        {
          body: "",
          title: "",
        },
      ],
    },
  });

  useEffect(() => {
    document.title = "Add PDP | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Successful",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot add changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !(user as AmpUserDetail)?.email_verified &&
      (user as AmpUserDetail).role !== "ADMIN"
    ) {
      navigate("/dashboard");
    }
  }, [navigate, user]);

  async function onSubmit() {
    const selectionsRaw = getValues('selections')
    const payload: Partial<CreateAmpPdpPayload> = {
      id: isEdit ? id : undefined,
      venue_id: getValues("venue") ?? "",
      h1: getValues("h1") ?? "",
      page_title: getValues("page_title") ?? "",
      meta_description: getValues("meta_description") || "",
      overview: getValues("overview") || "",
      important_info: getValues('importantInfo'),
      included_body: getValues("included.body"),
      included: getValues("included.included"),
      excluded: getValues("included.excluded"),
      faqs: getValues("faqs"),
    };

    for (let i = 0; i < getValues("media").length; i++) {
      const media = getValues("media")[i];
      const selectionIndex = selections.fields.findIndex(s => s.id === media.metadata?.selection)
      if (selectionIndex === -1) continue;
      if (media.image_db_id) {
        selectionsRaw[selectionIndex].image = media.image_db_id;
      } else {
        setUploadingMedia(true);
        const formData = new FormData();
        formData.append("file", media.url);
        const uploadedMedia = await uploadMedia({
          file: formData,
        });
        if (isSuccessResponse(uploadedMedia)) {
          selectionsRaw[selectionIndex].image = uploadedMedia.data.id;
        } else {
          console.log("ERROR Uploading Media", uploadedMedia.error);
        }
        setUploadingMedia(false);
      }
    }

    payload.selections = selectionsRaw
    const results = await addPdp(payload as CreateAmpPdpPayload);

    if ("data" in results && !isEdit) {
      navigate(`/dashboard/pdp`, { replace: true });
    }

    await refetch();
    restartAnimation();
  }

  useEffect(() => {
    if (getQuery && isQuerySuccess && !isFetching) {
      let media: (z.infer<typeof mediaSchema>[number] | undefined)[] = [];
      const selections = ((typeof getQuery.selections === 'string' ? JSON.parse(getQuery.selections) : getQuery.selections) || []) as AddPdpForm['selections'];

      (async () => {
        media = await Promise.all(
          parseMediaString(getQuery.media || "").filter(s => s)?.map(async (media) => {
            const data = await fetchFileData(media?.url);
            return {
              url: data.data,
              urlString: ((await fileToDataString(data.data)) || "") as string,
              filename: media.id,
              size: data.size,
              createdAt: new Date(),
              isPrimary: false,
              persisted: true,
              image_db_id: media.id,
            };
          }) ?? []
        );

        reset(
          {
            venue: (getQuery.venue_id || "") as string,
            h1: getQuery.h1,
            page_title: getQuery.page_title,
            meta_description: getQuery.meta_description,
            overview: getQuery.overview,
            importantInfo: getQuery.important_info,
            selections: selections,
            faqs: (typeof getQuery.faqs === 'string' ? JSON.parse(getQuery.faqs) : getQuery.faqs) || [],
            included: {
              body: getQuery.included_body,
              included: (typeof getQuery.included === 'string' ? JSON.parse(getQuery.included) : getQuery.included) || [],
              excluded: (typeof getQuery.excluded === 'string' ? JSON.parse(getQuery.excluded) : getQuery.excluded) || [],
            },
            media,
          },
          {
            keepDirty: false,
          }
        );
      })()
    }
  }, [getQuery, isFetching, isQuerySuccess, reset]);

  const defaultAttractions = (getQuery?.venue && isEdit) ? {
    label: getQuery?.venue?.name ?? "",
    value: (getQuery?.venue_id ?? "") as string,
    attraction: {
      id: (getQuery?.venue_id as string) ?? "",
      image: "",
      image_alt: "",
      location: "",
      title: getQuery?.venue?.name ?? "",
    },
    media: getQuery?.venue?.media,
  } : null;

  const selections = useFieldArray({
    control,
    name: "selections",
  });

  const faqs = useFieldArray({
    control,
    name: "faqs",
  });

  const included = useFieldArray({
    control,
    name: "included.included",
  });

  const excluded = useFieldArray({
    control,
    name: "included.excluded",
  });

  const mediaControls = useFieldArray({
    control,
    name: "media",
    rules: {
      minLength: 0,
    },
  });
  const { fields } = mediaControls;

  const [croppingImage, setCroppingImage] = useState<number>();
  const [getShowDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [getShowCropModal, setShowCropModal] = useState<boolean>(false);
  const { createMedia, removeBasedOnId, cancelCrop, cropImage } = useMediaUpload({
    getValues: getValues as unknown as UseFormGetValues<{
      media: z.infer<typeof mediaSchema>;
    }>,
    controls: mediaControls as unknown as UseFieldArrayReturn<
      {
        media: z.infer<typeof mediaSchema>;
      },
      "media",
      "id"
    >,
    croppingImage,
    setCroppingImage,
    setShowCropModal,
    setShowDeleteModal,
  });

  const [uploadingMedia, setUploadingMedia] = useState(false);

  useEffect(() => {
    const media = getValues('media')
    if (!media) return;
    if (!media?.[0]?.metadata) {
      for (let i = 0; i < media.length; i++) {
        const image = media[i]
        mediaControls.update(i, { ...image, metadata: { selection: selections.fields.find(s => s.image === image.image_db_id)?.id } })
      }
    }
  }, [selections, getValues, setValue, mediaControls]);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA]">
      <Header title={!isEdit ? "Add PDP" : "Edit PDP"} showBack />

      <SingleAttractionTemplate name={"PDP"}>
        <form onSubmit={handleSubmit(onSubmit)} className="xl:mt-0 mt-[70px]">
          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 max-w-[570px] xl:w-3/5 gap-y-5">
            <div>
              <label className="text-black text-sm font-bold">Venue</label>
              <AttractionSearch
                value={watch("venue") ?? ""}
                defaultOptions={defaultAttractions}
                onChange={(value) => setValue("venue", value)}
              />
              {errors["venue"]?.message && (
                <ErrorMessage>{errors["venue"]?.message}</ErrorMessage>
              )}
            </div>

            <div className={`flex flex-col w-full mt-5`}>

              <div className="font-bold text-sm">H1</div>

              <TextInput
                className={`group`}
                labelClassName="group-focus-within:text-cs-pink text-sm font-bold !text-[#5F646D]"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
                control={control}
                name={`h1`}
                label={""}
                inputProps={{
                  ...register(`h1`),
                  placeholder: "",
                  type: "string",
                }}
                hideLabelMargin={true}
              />
            </div>
            <div className={`flex flex-col w-full mt-5`}>
              <div className="font-bold text-sm">Page Title</div>
              <TextInput
                className={`group`}
                labelClassName="group-focus-within:text-cs-pink text-sm font-bold !text-[#5F646D]"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
                control={control}
                name={`page_title`}
                label={""}
                inputProps={{
                  ...register(`page_title`),
                  placeholder: "",
                  type: "string",
                }}
                hideLabelMargin={true}
              />
            </div>
            <div className={`flex flex-col w-full mt-5`}>
              <div className="font-bold text-sm">Meta Description</div>
              <TextInput
                className={`group`}
                labelClassName="group-focus-within:text-cs-pink text-sm font-bold !text-[#5F646D]"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
                control={control}
                name={`meta_description`}
                label={""}
                inputProps={{
                  ...register(`meta_description`),
                  placeholder: "",
                  type: "string",
                }}
                hideLabelMargin={true}
              />
            </div>

            <div className={`flex flex-col w-full mt-5`}>
              <div className="font-bold text-sm">Highlights</div>

              <div className="relative">
                {(getQuery?.overview !== undefined || !isEdit) && (
                  <RichTextEditor
                    charLabel={``}
                    isWordCount={true}
                    maxChars={1000}
                    maxCharSuffix={" words"}
                    initialValue={getQuery?.overview ?? ""}
                    onChange={(value: string) => setValue("overview", value)}
                    onDirty={() =>
                      setTimeout(() => {
                        setValue("overview", getValues("overview"), {
                          shouldDirty: true,
                        });
                      }, 100)
                    }
                    placeholder=""
                    label=""
                  />
                )}
              </div>

              {errors["overview"] && (
                <ErrorMessage>{errors["overview"]?.message}</ErrorMessage>
              )}
            </div>

            <div className={`flex flex-col w-full mt-5`}>
              <div className="font-bold text-sm">Important Information</div>

              <div className="relative">
                {(getQuery?.important_info !== undefined || !isEdit) && (
                  <RichTextEditor
                    charLabel={``}
                    isWordCount={true}
                    maxChars={1000}
                    maxCharSuffix={" words"}
                    initialValue={getQuery?.important_info ?? ""}
                    onChange={(value: string) =>
                      setValue("importantInfo", value)
                    }
                    onDirty={() =>
                      setTimeout(() => {
                        setValue("importantInfo", getValues("importantInfo"), {
                          shouldDirty: true,
                        });
                      }, 100)
                    }
                    placeholder=""
                    label=""
                  />
                )}
              </div>

              {errors["importantInfo"] && (
                <ErrorMessage>{errors["importantInfo"]?.message}</ErrorMessage>
              )}
            </div>

            <div className={`flex flex-col w-full mt-5`}>
              <div className="font-bold text-sm">Whats Included Copy
              </div>

              <div className="relative">
                {(getQuery?.included_body !== undefined || !isEdit) && (
                  <RichTextEditor
                    charLabel={``}
                    isWordCount={true}
                    maxChars={1000}
                    maxCharSuffix={" words"}
                    initialValue={getQuery?.included_body ?? ""}
                    onChange={(value: string) =>
                      setValue("included.body", value)
                    }
                    onDirty={() =>
                      setTimeout(() => {
                        setValue("included.body", getValues("included.body"), {
                          shouldDirty: true,
                        });
                      }, 100)
                    }
                    placeholder=""
                    label=""
                  />
                )}
              </div>

              {errors["included"]?.["body"] && (
                <ErrorMessage>
                  {errors["included"]?.["body"]?.message}
                </ErrorMessage>
              )}
            </div>
          </fieldset>

          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 max-w-[570px] xl:w-3/5 gap-y-5">
            <div className={`flex flex-col w-full mt-5`}>
              <div className="font-bold text-sm mb-5">Whats Included Bullet Points</div>

              {included.fields.map((inc, i) => (
                <div
                  key={JSON.stringify(inc) + i.toString()}
                  className="relative inline-flex items-center gap-10 mb-5"
                >
                  <div className="grid grid-cols-1 min-w-[300px] w-[500px] max-w-full gap-2.5">
                    <TextInput
                      className={`group`}
                      labelClassName="group-focus-within:text-cs-pink text-sm font-bold !text-[#5F646D]"
                      inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
                      control={control}
                      name={`included.included.${i}.value`}
                      label={""}
                      inputProps={{
                        ...register(`included.included.${i}.value`),
                        placeholder: "",
                        type: "string",
                      }}
                      hideLabelMargin={true}
                    />
                  </div>

                  {i === 0 && (
                    <button
                      type="button"
                      className="flex-grow self-center flex items-center justify-start -translate-y-3"
                      onClick={() =>
                        included.append({
                          value: "",
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        className="mr-2 mt-6"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z"
                        />
                      </svg>
                    </button>
                  )}

                  {i !== 0 && (
                    <button
                      type="button"
                      className="flex-grow self-center flex items-center justify-start"
                      onClick={() => included.remove(i)}
                    >
                      <svg
                        className="mr-2 mt-6"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5z"
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              ))}
            </div>
          </fieldset>

          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 max-w-[570px] xl:w-3/5 gap-y-5">
            <div className={`flex flex-col w-full mt-5`}>
              <div className="font-bold text-sm mb-5">Whats Not Included Bullet Points</div>

              {excluded.fields.map((inc, i) => (
                <div
                  key={JSON.stringify(inc) + i.toString()}
                  className="relative inline-flex items-center gap-10 mb-5"
                >
                  <div className="grid grid-cols-1 min-w-[300px] w-[500px] max-w-full gap-2.5">
                    <TextInput
                      className={`group`}
                      labelClassName="group-focus-within:text-cs-pink text-sm font-bold !text-[#5F646D]"
                      inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
                      control={control}
                      name={`included.excluded.${i}.value`}
                      label={""}
                      inputProps={{
                        ...register(`included.excluded.${i}.value`),
                        placeholder: "",
                        type: "string",
                      }}
                      hideLabelMargin={true}
                    />
                  </div>

                  {i === 0 && (
                    <button
                      type="button"
                      className="flex-grow self-center flex items-center justify-start -translate-y-3"
                      onClick={() =>
                        excluded.append({
                          value: "",
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        className="mr-2 mt-6"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z"
                        />
                      </svg>
                    </button>
                  )}

                  {i !== 0 && (
                    <button
                      type="button"
                      className="flex-grow self-center flex items-center justify-start"
                      onClick={() => excluded.remove(i)}
                    >
                      <svg
                        className="mr-2 mt-6"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5z"
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              ))}
            </div>
          </fieldset>

          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 max-w-[570px] xl:w-3/5 gap-y-5">
            <div className={`flex flex-col w-full mt-5`}>
              <div className="font-bold text-sm mb-2.5">New Section</div>

              {selections.fields.map((selection, i) => (
                <div
                  key={JSON.stringify(selection) + i.toString()}
                  className="relative inline-flex gap-10 mb-5"
                >
                  <div className="grid grid-cols-1 min-w-[300px] w-[500px] max-w-full gap-2.5">
                    <TextInput
                      className={`group`}
                      labelClassName="group-focus-within:text-cs-pink text-sm font-bold !text-[#5F646D]"
                      inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
                      control={control}
                      name={`selections.${i}.title`}
                      label={"Title"}
                      inputProps={{
                        ...register(`selections.${i}.title`),
                        placeholder: "",
                        type: "string",
                      }}
                      hideLabelMargin={true}
                    />

                    <Controller
                      control={control}
                      name={`selections.${i}.body`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <RichTextEditor
                          label="Content"
                          charLabel={""}
                          initialValue={value || ""}
                          onChange={onChange}
                        />
                      )}
                    />

                    {(fields.find(s => s.metadata?.selection === selection.id) && fields.find(s => s.metadata?.selection === selection.id)?.url) ? (
                      <Media
                        className="w-full xl:w-1/2"
                        index={0}
                        size={fields.find(s => s.metadata?.selection === selection.id)!.size}
                        createdAt={fields.find(s => s.metadata?.selection === selection.id)!.createdAt}
                        url={fields.find(s => s.metadata?.selection === selection.id)!.url as unknown as File}
                        isPrimary={false}
                        filename={fields.find(s => s.metadata?.selection === selection.id)!.filename}
                        checked={false}
                        togglePrimary={() => { }}
                        remove={() => removeBasedOnId(fields.find(s => s.metadata?.selection === selection.id)!.id)}
                        toggleSelect={() => { }}
                      />
                    ) : (
                      <MediaUploadArea
                        uploadable={true}
                        createMedia={(event) => createMedia(event, undefined, undefined, { selection: selection.id })}
                        fullSize={true}
                      />
                    )}
                  </div>

                  {i === 0 && (
                    <button
                      type="button"
                      className="flex-grow self-center flex items-center justify-start"
                      onClick={() =>
                        selections.append({
                          title: "",
                          body: "",
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        className="mr-2 mt-6"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z"
                        />
                      </svg>
                    </button>
                  )}

                  {i !== 0 && (
                    <button
                      type="button"
                      className="flex-grow self-center flex items-center justify-start"
                      onClick={() => selections.remove(i)}
                    >
                      <svg
                        className="mr-2 mt-6"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5z"
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              ))}
            </div>
          </fieldset>

          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 max-w-[570px] xl:w-3/5 gap-y-5">
            <div className={`flex flex-col w-full mt-5`}>
              <div className="font-bold text-sm">FAQs</div>

              {faqs.fields.map((faq, i) => (
                <div
                  key={JSON.stringify(faq) + i.toString()}
                  className="relative inline-flex gap-10 mb-5"
                >
                  <div className="grid grid-cols-1 min-w-[300px] w-[500px] max-w-full gap-2.5">
                    <TextInput
                      className={`group`}
                      labelClassName="group-focus-within:text-cs-pink text-sm font-bold !text-[#5F646D]"
                      inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
                      control={control}
                      name={`faqs.${i}.title`}
                      label={"Title"}
                      inputProps={{
                        ...register(`faqs.${i}.title`),
                        placeholder: "",
                        type: "string",
                      }}
                      hideLabelMargin={true}
                    />

                    <Controller
                      control={control}
                      name={`faqs.${i}.content`}
                      render={({ field: { ref, value, onChange }, fieldState: {error} }) => (
                        <>
                          <textarea
                            value={value ?? ""}
                            onChange={onChange}
                            placeholder=""
                            className="text-cs-gray placeholder:text-sm text-md p-3 w-full mt-2 border-[#CFDBD5] rounded-lg border-cs-1 outline-cs-pink h-40"
                          ></textarea>

                          {error?.message && <ErrorMessage>{error?.message}</ErrorMessage>}
                        </>
                      )}
                    />
                  </div>

                  {i === 0 && (
                    <button
                      type="button"
                      className="flex-grow self-center flex items-center justify-start"
                      onClick={() =>
                        faqs.append({
                          title: "Booking FAQs",
                          content: "",
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        className="mr-2 mt-6"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z"
                        />
                      </svg>
                    </button>
                  )}

                  {i !== 0 && (
                    <button
                      type="button"
                      className="flex-grow self-center flex items-center justify-start"
                      onClick={() => faqs.remove(i)}
                    >
                      <svg
                        className="mr-2 mt-6"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5z"
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              ))}
            </div>
          </fieldset>

          {getShowCropModal && (
            <ModalWrapper
              closeOnOverlay={false}
              className="w-[616px] min-h-cs-394 px-0 sm:px-10 xl:px-0"
              open={getShowCropModal}
              setOpen={setShowCropModal}
              onClose={cancelCrop}
            >
              <h3 className="mb-3 text-2xl font-bold text-cs-gray">
                Crop Image
              </h3>

              <ImageCropper
                file={fields[croppingImage ?? 0]?.url ?? ""}
                stencilAspectRatio={16 / 9}
                onAccept={(event) => cropImage(event)}
                onCancel={cancelCrop}
              />
            </ModalWrapper>
          )}

          <PrimaryButton type="submit" loading={isLoading || uploadingMedia} scale="sm">
            {isLoading ? (
              <>
                <span className="flex justify-center">
                  <svg
                    className="w-5 h-5 mr-3 animate-spin text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </span>
              </>
            ) : !isEdit ? (
              "Add PDP"
            ) : (
              "Save Changes"
            )}
          </PrimaryButton>
          {isSubmitted && !isValid && (
            <ErrorMessage>
              You have some errors! Scroll up to view & fix
            </ErrorMessage>
          )}

          {error && "data" in error && (
            <ErrorMessage>
              {error?.data?.message?.replaceAll('"', "") ?? ""}
            </ErrorMessage>
          )}
        </form>
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AddPdp;

const Media: React.FC<{
  className?: string;
  index: number;
  url: File;
  filename: string;
  size: number;
  createdAt: Date;
  isPrimary: boolean;
  checked: boolean;
  togglePrimary: (index: number) => void;
  remove: (index: number) => void;
  toggleSelect: (index: number) => void;
}> = ({ className, index, url, filename, checked, remove, toggleSelect }) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [urlString, setUrlString] = useState("");

  useEffect(() => {
    (async () => {
      setUrlString((await fileToDataString(url)) as string);
    })();
  }, [url]);
  return (
    <>
      <div className={`flex flex-col ${className ?? ""}`}>
        <div className="relative flex mt-2">
          <div className="absolute flex justify-between w-full p-4">
            <input
              type="checkbox"
              checked={checked}
              onChange={() => toggleSelect(index)}
            />

            <span className="text-sm">
              <button
                className="text-red-700"
                type="button"
                onClick={() => setDeleteModal(true)}
              >
                <svg
                  width="20"
                  height="23"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.90625 4.20508V2.14258H13.0938V4.20508H6.90625ZM4.84375 4.20508V1.45508C4.84375 1.09041 4.98862 0.740669 5.24648 0.482806C5.50434 0.224944 5.85408 0.0800781 6.21875 0.0800781L13.7812 0.0800781C14.1459 0.0800781 14.4957 0.224944 14.7535 0.482806C15.0114 0.740669 15.1562 1.09041 15.1562 1.45508V4.20508H18.5938C18.8673 4.20508 19.1296 4.31373 19.323 4.50712C19.5163 4.70052 19.625 4.96282 19.625 5.23633C19.625 5.50983 19.5163 5.77214 19.323 5.96553C19.1296 6.15893 18.8673 6.26758 18.5938 6.26758H18.0919L17.0702 19.5405C17.0172 20.2313 16.7054 20.8767 16.1971 21.3476C15.6887 21.8184 15.0214 22.08 14.3285 22.0801H5.6715C4.97861 22.08 4.31127 21.8184 3.80294 21.3476C3.29461 20.8767 2.98276 20.2313 2.92975 19.5405L1.90813 6.26758H1.40625C1.13275 6.26758 0.870443 6.15893 0.677046 5.96553C0.483649 5.77214 0.375 5.50983 0.375 5.23633C0.375 4.96282 0.483649 4.70052 0.677046 4.50712C0.870443 4.31373 1.13275 4.20508 1.40625 4.20508H4.84375ZM3.9775 6.26758H16.0225L15.0133 19.3823C15.0001 19.555 14.9223 19.7163 14.7953 19.8341C14.6684 19.9519 14.5017 20.0174 14.3285 20.0176H5.6715C5.49834 20.0174 5.33163 19.9519 5.20468 19.8341C5.07774 19.7163 4.99991 19.555 4.98675 19.3823L3.9775 6.26758Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </span>
          </div>

          <img
            className="w-full aspect-[16/9] object-cover rounded-sm border border-slate-100"
            src={urlString}
            alt={filename}
          />
        </div>
      </div>

      <ModalWrapper
        className="w-[500px] px-10 xl:px-0"
        open={deleteModal}
        setOpen={setDeleteModal}
      >
        <h3 className="text-2xl font-bold text-cs-gray">Delete Image?</h3>
        <p className="text-base font-normal my-7 text-cs-gray">
          You are about to delete a image from your gallery.
        </p>
        <h4 className="text-lg font-bold text-cs-gray">Are you sure?</h4>

        <div className="flex flex-row mt-10">
          <button
            type="button"
            className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
            onClick={() => remove(index)}
          >
            Yes, Delete it
          </button>

          <button
            type="button"
            className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            No, Don't delete it
          </button>
        </div>
      </ModalWrapper>
    </>
  );
};

export function MediaUploadArea({
  uploadable,
  createMedia,
  fullSize = false,
  className = "xl:w-1/2",
}: {
  uploadable: boolean;
  fullSize?: boolean;
  className?: string;
  createMedia: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}) {
  return (
    <div
      className={`flex flex-col w-full ${fullSize
        ? className
        : "sm:w-[calc(50%-1.5rem)] md:w-[calc(25%-1.5rem)] [@media(min-width:2400px)]:w-[calc(16.666%-1.5rem)]"
        }`}
    >
      <div className="relative flex flex-col mt-2">
        <div
          className={`flex justify-center items-center text-center px-10 w-full aspect-[16/9] border-2 border-dotted border-[#CFDBD5] cursor-pointer relative ${!uploadable ? "opacity-50 pointer-events-none" : ""
            }`}
        >
          {uploadable ? (
            <span className="text-cs-gray text-xs leading-[18px]">
              <p>Drop image here</p>
              <p>Upload image</p>
            </span>
          ) : (
            <span>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="camera-01">
                  <g id="Icon">
                    <path
                      d="M3.19141 11.963C3.19141 11.4864 3.19141 11.2482 3.21129 11.0475C3.40307 9.11187 4.93431 7.58063 6.86993 7.38885C7.07062 7.36897 7.32175 7.36897 7.824 7.36897C8.01753 7.36897 8.1143 7.36897 8.19645 7.36399C9.24555 7.30046 10.1642 6.63832 10.5562 5.66316C10.5869 5.58679 10.6156 5.5007 10.673 5.32853C10.7304 5.15635 10.7591 5.07026 10.7898 4.9939C11.1818 4.01873 12.1005 3.3566 13.1496 3.29306C13.2318 3.28809 13.3225 3.28809 13.504 3.28809H20.0847C20.2662 3.28809 20.3569 3.28809 20.4391 3.29306C21.4882 3.3566 22.4069 4.01873 22.7989 4.9939C22.8296 5.07026 22.8583 5.15635 22.9157 5.32853C22.9731 5.5007 23.0018 5.58679 23.0325 5.66316C23.4245 6.63832 24.3431 7.30046 25.3922 7.36399C25.4744 7.36897 25.5712 7.36897 25.7647 7.36897C26.2669 7.36897 26.5181 7.36897 26.7188 7.38885C28.6544 7.58063 30.1856 9.11187 30.3774 11.0475C30.3973 11.2482 30.3973 11.4864 30.3973 11.963V22.6043C30.3973 24.8898 30.3973 26.0325 29.9525 26.9055C29.5613 27.6733 28.937 28.2976 28.1691 28.6889C27.2961 29.1337 26.1534 29.1337 23.8679 29.1337H9.72082C7.43531 29.1337 6.29256 29.1337 5.41961 28.6889C4.65174 28.2976 4.02744 27.6733 3.6362 26.9055C3.19141 26.0325 3.19141 24.8898 3.19141 22.6043V11.963Z"
                      stroke="#CFDBD5"
                      strokeWidth="2.59653"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.7943 23.0123C19.7994 23.0123 22.2355 20.5763 22.2355 17.5712C22.2355 14.5661 19.7994 12.13 16.7943 12.13C13.7893 12.13 11.3532 14.5661 11.3532 17.5712C11.3532 20.5763 13.7893 23.0123 16.7943 23.0123Z"
                      stroke="#CFDBD5"
                      strokeWidth="2.59653"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </span>
          )}

          <input
            className="absolute inset-0 w-full h-full opacity-0 appearance-none cursor-pointer"
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={createMedia}
          />
        </div>
      </div>
    </div>
  );
}

function parseMediaString(mediaString: string) {
  const values = mediaString.slice(1, -1).split(',');

  const id = values[0];
  const url = values[4];

  return [
    {
      url: url,
      id: id,
    }
  ];
}
