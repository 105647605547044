import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { createRef, ReactNode, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { useAppDispatch, useAppSelector } from "../../../store";
import { PrimaryButton } from "../../../components/global/PrimaryButton";
import TextInput from "../../../components/form/TextInput";
import { z } from "zod";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ModalWrapper from "../../../components/global/ModalWrapper";
import { apiSlice } from "../../../store/apiSlice";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import { globalActions } from "../../../store/globalSlice";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import { postcodeValidation } from "../../../components/global/www/modals/SearchClaimModal";
import { QRious } from "react-qrious";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { TAndC } from "./TermsAndConditions";

export default function Onboarding() {
  useEffect(() => {
    document.title = "Tickets Onboarding | Day Out With The Kids";
  }, []);

  // http://localhost:3000/attractions/wcdb67gv/ticketing?envelopeId=9ae35da6-871a-4639-8fe5-3d8cf272c543&event=signing_complete
  const [searchParams] = useSearchParams();
  const envelopeId = searchParams.get("envelopeId");
  const finishedSigning = searchParams.get("event") === "signing_complete";

  const { attractionId } = useParams();
  const {
    data: getAttraction,
    isSuccess,
    isFetching,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");

  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.global);
  useEffect(() => {
    // if (user?.role === "ADMIN") return;
  }, [navigate, user?.role]);

  // const [step, setStep] = useState(finishedSigning ? 4 : 0);
  const [step, setStep] = useState(0);

  const schema = z.object({
    fullName: z.string().optional(),
    email: z.string().optional(),

    attractionName: z.string().min(1, { message: "Required" }),
    businessName: z.string().min(1, { message: "Required" }).max(255, 'Too long'),
    businessAddress: z.string().min(1, { message: "Required" }),
    businessPostcode: postcodeValidation,
    vatNumber: z.string(),
    companyName: z.string(),
    financeContactName: z.string().min(1, { message: "Required" }),
    financeContactNumber: z
      .string()
      .regex(
        /^(\+44\s?|\(0\d{4}\)\s?|\(0\d{3}\)\s?|\(0\d{2}\)\s?|\d{4}\s?|\d{3}\s?|\d{2}\s?)\d{3}\s?\d{4}(\s?\#\d{3,4})?$/,
        { message: "Invalid phone number" }
      ),
    financeContactEmail: z.string().email(),
    bankName: z.string().min(1, { message: "Required" }),
    bankAccountName: z.string().min(1, { message: "Required" }),
    bankAccountSortCode: z
      .string()
      .regex(/^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/, {
        message: "Invalid",
      }),
    bankAccountNumber: z
      .string()
      .regex(/^(?:\d{6}\s?\d{2}|\d{8})$/, { message: "Invalid" }),
  });
  type Form = z.infer<typeof schema>;
  const formMethods = useForm<Form>({
    resolver: async (data, context, options) => {
      return zodResolver(schema)(data, context, options);
    },
  });

  useEffect(() => {
    if (getAttraction?.result && isSuccess && !isFetching) {
      formMethods.reset(
        {
          ...formMethods.getValues(),
          attractionName: getAttraction?.result?.name ?? "",
        },
        {
          keepDirty: false,
        }
      );
    }
  }, [getAttraction, isSuccess, isFetching]);

  const errorsEmpty = Object.keys(formMethods.formState.errors).length === 0;

  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const [createTicketOnboardingUser, { isLoading }] =
    apiSlice.useCreateTicketOnboardingUserMutation();
  const [sessionDataTrigger] = apiSlice.useLazySessionQuery();
  const onSubmit: SubmitHandler<Form> = async (e) => {
    const result = await createTicketOnboardingUser({
      attraction_name: e.attractionName,
      business_name: e.businessName,
      business_address: e.businessAddress,
      business_postcode: e.businessPostcode,
      vat: e.vatNumber,
      company_number: e.companyName,
      finance_contact_name: e.financeContactName,
      finance_contact_number: e.financeContactNumber,
      finance_contact_email: e.financeContactEmail,
      bank_name: e.bankName,
      bank_account_name: e.bankAccountName,
      bank_account_sort_code: e.bankAccountSortCode,
      bank_account_number: e.bankAccountNumber,
      envelopeId,
    });

    if ("data" in result) {
      await sessionDataTrigger();
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Success",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    } else {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Something went wrong",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
    restartAnimation();
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }, []);
  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Ticket Manager Dashboard"
        mobilePublishButtons={false}
        showPublishingButtons={false}
        hideLogo={true}
      />

      <div className="h-0 xl:h-[99px]"></div>

      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          className="xl:mt-0 mt-[-160px]"
        >
          {step === 0 ? (
            <StepZero
              onNext={() => {
                setStep((s) => s + 1);
                window.scrollTo(0, 0);
              }}
            />
          ) : step === 1 ? (
            <StepOne
              onNext={() => {
                setStep((s) => s + 1);
                window.scrollTo(0, 0);
              }}
            />
          ) : step === 2 ? (
            <StepTwo
              onNext={() => {
                setStep((s) => s + 1);
                window.scrollTo(0, 0);
              }}
            />
          ) : step === 3 ? (
            <StepThree
              data={getAttraction?.result}
              onNext={() => {
                setStep((s) => s + 1);
                window.scrollTo(0, 0);
              }}
            />
          ) : (
            <StepFour
              isLoading={isLoading}
              hasError={!formMethods.formState.isValid && !errorsEmpty}
            />
          )}
        </form>
      </FormProvider>

      <div className="h-12"></div>

      <Footer />
    </div>
  );
}

function Container({ children }: { children: ReactNode }) {
  return (
    <DashboardContainer>
      <section
        className={`flex flex-col lg:mx-[20px] xl:mx-[70px] p-5 bg-white xl:rounded-lg`}
      >
        {children}
      </section>
    </DashboardContainer>
  );
}

function StepZero({ onNext }: { onNext: () => void }) {
  return (
    <>
      <Container>
        <div className="w-full">
          <div>
            <h1 className="font-extrabold text-[22px] leading-[26px] flex flex-wrap gap-5 items-center">
              <div>
                Get Started with Ticket Manager...it’s FREE to use.{" "}
                <span className="text-sm font-normal">
                  (onboarding only takes 10 minutes)
                </span>
              </div>

              <PrimaryButton
                scale="sm"
                className="ml-auto !leading-[10px] px-[31px] !bg-[#58CF12]"
                type="button"
                onClick={() => onNext()}
              >
                Get Started
              </PrimaryButton>
            </h1>

            <div className="text-sm font-normal flex flex-col gap-[15px] mt-[15px]">
              <p>
                Ticket Manager is an all-in-one ‘NO DEV’ solution that handles
                everything in the ticketing process, from managing inventory to
                processing payments, delivering tickets, and providing
                post-visit reports.
              </p>

              <p>
                For customers, Ticket Manager simplifies payments and ticket
                delivery with a QR eticket, scanned at the gate with our easy
                online tool, reducing friction and increasing purchase
                conversion rates.
              </p>
              <p>
                This quick onboarding process lets you start selling tickets on
                Day Out With The Kids. It covers everything you need to know
                about commissions, payments, what customers receive, and how to
                check and redeem tickets at your attraction.
              </p>

              <div
                className={`relative flex flex-wrap justify-around lg:gap-0 gap-5 mt-[15px]`}
              >
                <div className="flex-grow hidden lg:block"></div>
                <StepCard
                  img="/assets/amp/ticketing/onboarding/step-one.png"
                  text={"Add Inventory (ticket types & prices)"}
                  number={1}
                />
                <div className="flex-grow relative hidden lg:flex justify-center items-center">
                  <svg
                    className=""
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.8973 14.175C22.0975 13.9747 22.21 13.7032 22.21 13.42C22.21 13.1369 22.0975 12.8653 21.8973 12.6651L15.8568 6.62457C15.7583 6.52258 15.6405 6.44124 15.5102 6.38527C15.3799 6.32931 15.2398 6.29986 15.098 6.29862C14.9562 6.29739 14.8156 6.32441 14.6844 6.3781C14.5532 6.43179 14.434 6.51108 14.3337 6.61133C14.2334 6.71159 14.1542 6.83081 14.1005 6.96204C14.0468 7.09327 14.0198 7.23388 14.021 7.37566C14.0222 7.51744 14.0517 7.65756 14.1076 7.78783C14.1636 7.91811 14.2449 8.03593 14.3469 8.13443L18.5647 12.3522L4.4239 12.3522C4.1407 12.3522 3.8691 12.4647 3.66885 12.665C3.4686 12.8652 3.3561 13.1368 3.3561 13.42C3.3561 13.7032 3.4686 13.9748 3.66885 14.1751C3.8691 14.3753 4.1407 14.4878 4.4239 14.4878L18.5647 14.4878L14.3469 18.7056C14.1524 18.907 14.0448 19.1767 14.0472 19.4567C14.0497 19.7367 14.162 20.0045 14.3599 20.2025C14.5579 20.4005 14.8257 20.5128 15.1057 20.5152C15.3857 20.5176 15.6554 20.41 15.8568 20.2155L21.8973 14.175Z"
                      fill="#2D1838"
                    />
                  </svg>
                </div>
                <StepCard
                  img="/assets/amp/ticketing/onboarding/step-two.png"
                  text={"Customer receives mobile ticket via email"}
                  number={2}
                />
                <div className="flex-grow relative hidden lg:flex justify-center items-center">
                  <svg
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.8973 14.175C22.0975 13.9747 22.21 13.7032 22.21 13.42C22.21 13.1369 22.0975 12.8653 21.8973 12.6651L15.8568 6.62457C15.7583 6.52258 15.6405 6.44124 15.5102 6.38527C15.3799 6.32931 15.2398 6.29986 15.098 6.29862C14.9562 6.29739 14.8156 6.32441 14.6844 6.3781C14.5532 6.43179 14.434 6.51108 14.3337 6.61133C14.2334 6.71159 14.1542 6.83081 14.1005 6.96204C14.0468 7.09327 14.0198 7.23388 14.021 7.37566C14.0222 7.51744 14.0517 7.65756 14.1076 7.78783C14.1636 7.91811 14.2449 8.03593 14.3469 8.13443L18.5647 12.3522L4.4239 12.3522C4.1407 12.3522 3.8691 12.4647 3.66885 12.665C3.4686 12.8652 3.3561 13.1368 3.3561 13.42C3.3561 13.7032 3.4686 13.9748 3.66885 14.1751C3.8691 14.3753 4.1407 14.4878 4.4239 14.4878L18.5647 14.4878L14.3469 18.7056C14.1524 18.907 14.0448 19.1767 14.0472 19.4567C14.0497 19.7367 14.162 20.0045 14.3599 20.2025C14.5579 20.4005 14.8257 20.5128 15.1057 20.5152C15.3857 20.5176 15.6554 20.41 15.8568 20.2155L21.8973 14.175Z"
                      fill="#2D1838"
                    />
                  </svg>
                </div>
                <StepCard
                  img="/assets/amp/ticketing/onboarding/step-three.png"
                  text={"Attraction scans / redeems customer ticket at gate"}
                  number={3}
                />
                <div className="flex-grow hidden lg:block"></div>
              </div>

              <div className="flex">
                <PrimaryButton
                  scale="sm"
                  className="ml-auto !leading-[10px] px-[31px] !bg-[#58CF12]"
                  type="button"
                  onClick={() => onNext()}
                >
                  Get Started
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export const commissionMap: Record<
  Exclude<AttractionDetails["listing_package"], "EVENT">,
  number | string
> = {
  BASIC: "20",
  STANDARD: 15,
  ENHANCED: 10,
  PREMIUM: 10,
};
function StepOne({ onNext }: { onNext: () => void }) {
  const [showTOS, setShowTOS] = useState(false);
  return (
    <Container>
      <ModalWrapper
        className="max-w-full w-[665px] md:px-10 xl:px-0 py-0"
        open={showTOS}
        setOpen={setShowTOS}
        hideCloseButton={true}
        customContainerClassName="relative flex flex-col items-center w-full p-[30px] bg-white rounded-lg"
      >
        <button
          type="button"
          className="absolute -top-5 right-0"
          onClick={() => setShowTOS(false)}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2281_17296)">
              <path
                d="M3.63992 2.27L7.49992 6.13L11.3399 2.29C11.4247 2.19972 11.5269 2.12749 11.6403 2.07766C11.7537 2.02783 11.876 2.00141 11.9999 2C12.2651 2 12.5195 2.10536 12.707 2.29289C12.8946 2.48043 12.9999 2.73478 12.9999 3C13.0023 3.1226 12.9795 3.24439 12.9331 3.35788C12.8866 3.47138 12.8175 3.57419 12.7299 3.66L8.83992 7.5L12.7299 11.39C12.8947 11.5512 12.9914 11.7696 12.9999 12C12.9999 12.2652 12.8946 12.5196 12.707 12.7071C12.5195 12.8946 12.2651 13 11.9999 13C11.8725 13.0053 11.7453 12.984 11.6265 12.9375C11.5078 12.8911 11.3999 12.8204 11.3099 12.73L7.49992 8.87L3.64992 12.72C3.56542 12.8073 3.46448 12.8769 3.35292 12.925C3.24136 12.9731 3.12138 12.9986 2.99992 13C2.7347 13 2.48035 12.8946 2.29281 12.7071C2.10528 12.5196 1.99992 12.2652 1.99992 12C1.99759 11.8774 2.02033 11.7556 2.06676 11.6421C2.11319 11.5286 2.18233 11.4258 2.26992 11.34L6.15992 7.5L2.26992 3.61C2.1051 3.44876 2.00846 3.23041 1.99992 3C1.99992 2.73478 2.10528 2.48043 2.29281 2.29289C2.48035 2.10536 2.7347 2 2.99992 2C3.23992 2.003 3.46992 2.1 3.63992 2.27Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2281_17296">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <div>
          <h3 className="text-[22px] font-bold text-black">
            Terms & Conditions
          </h3>
          <div className="w-full">
        
              <div className="overflow-y-scroll max-h-[50vh] py-10">
                <TAndC />
              </div>
          </div>

          <div className="flex flex-row justify-end mt-5">
            <PrimaryButton
              scale="sm"
              className="w-[151px]"
              onClick={() => {
                setShowTOS(false);
              }}
            >
              Continue
            </PrimaryButton>
          </div>
        </div>
      </ModalWrapper>
      <div className="w-full">
        <div>
          <div className="text-sm leading-[21px] font-normal flex flex-col gap-[15px]">
            <div>
              Before you start, we need to make you aware of a few things,
              you’ll only need to do this once, and when completed you’ll go
              straight to setting up your tickets. In this onboarding to Ticket
              Manager we’ll cover:
            </div>

            <ul className="pl-5 list-disc flex flex-col gap-[5px]">
              <li>How you add tickets and manage inventory</li>
              <li>
                How Customers/Visitors will receive their tickets and how they
                are redeemed at your attraction
              </li>
              <li>How cancellations & booking amends are handled</li>
              <li>The commission you’ll pay</li>
              <li>How you’ll get paid</li>
              <li>
                The Terms & Conditions - the legal stuff that covers most of the
                above
              </li>
            </ul>

            <div>
              Once we’ve gone through this, we’ll need a quick e-signature to
              confirm that you have understood the process, payment terms and
              commission structure. Day Out with the Kids will then complete
              some internal checks to ensure that all information is correct and
              legal.
            </div>

            <div>
              <div className="font-bold">Ticket Commission</div>
              We have made our commission model as clear and simple as possible.
              DOWTK charges a fixed commission based on your attraction's
              listing plan. The various commissions and listing plans are
              explained below:
            </div>

            <div>
              {Object.entries(commissionMap).map(([plan, commission]) => (
                <div>
                  <span className="font-bold">
                    {capFirstLetter(plan)} Listing Plan
                  </span>{" "}
                  -{" "}
                  
                    {commission}% Commission to DOWTK
                </div>
              ))}
            </div>

            <div>
              <div className="font-bold">Payments & Remittances</div>
              DOWTK handle all bookings and payments for your attraction. At the
              end of each month, we provide you with a detailed report showing
              your total sales, commissions, taxes, and charges.
              <br />
              We also send you a self-billing invoice. You'll receive payment
              from us within 14 days, provided you reach the minimum sales total
              of £500. You have 12 months to achieve this minimum. If your sales
              fall below £500, we will hold the revenue until you meet the
              minimum. If you do not reach £500 within 12 months, you may
              forfeit the balance in your ticketing account.
            </div>

            <div className="font-bold">
              Full Terms & Conditions available{" "}
              <button
                type="button"
                className="underline"
                onClick={() => setShowTOS(true)}
              >
                here
              </button>
            </div>

            <div className="h-[1px] bg-[#D9D9D9] w-full mt-5"></div>
            <div className="flex items-center gap-2.5 justify-end w-full">
              <PrimaryButton
                scale="sm"
                type="button"
                className={`w-[155px]`}
                onClick={onNext}
              >
                Continue
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

function StepTwo({ onNext }: { onNext: () => void }) {
  return (
    <Container>
      <div className="w-full">
        <div>
          <div className="font-extrabold text-[21px] text-[#212121]">
            Example Customer QR Mobile Ticket
          </div>

          <div className="mt-5 max-w-[569px] text-[#212121] border-[#F1F1F1] border-2 rounded-[10px] p-[30px] text-center">
            <QRious
              value={"<Attraction Name><Ticket Name><Price><DateofVisit>"}
              className="w-[141px] h-[141px] flex-shrink-0 mx-auto"
            />
            <div className="font-bold text-lg leading-[21px] mt-5">
              Attraction Name: ZOO Land
              <br />
              Ticket Name: Adult (16+)
              <br />
              Price: £26.00
              <br />
              Date of Visit: 28/04/2025
              <br />
              Booking Ref (up to 12 characters): 2001JM82UKAM
            </div>
          </div>

          <div className="text-sm leading-[21px] font-normal flex flex-col gap-[15px] mt-5 max-w-[723px]">
            <div className="flex gap-2 items-center">
              <svg
                className="flex-shrink-0"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2282_198)">
                  <path
                    d="M11.3452 4.39979L15.6069 8.66142C15.8185 8.87329 15.9373 9.1605 15.9373 9.45995C15.9373 9.75941 15.8185 10.0466 15.6069 10.2585L11.346 14.5209C11.134 14.7329 10.8465 14.8519 10.5467 14.8519C10.2469 14.8519 9.95941 14.7329 9.74742 14.5209C9.53544 14.3089 9.41635 14.0214 9.41635 13.7216C9.41635 13.4218 9.53544 13.1343 9.74742 12.9223L12.0798 10.59H3.39001C3.09032 10.59 2.8029 10.4709 2.59098 10.259C2.37906 10.0471 2.26001 9.75965 2.26001 9.45995C2.26001 9.16026 2.37906 8.87284 2.59098 8.66092C2.8029 8.449 3.09032 8.32995 3.39001 8.32995H12.0798L9.74818 5.99762C9.53629 5.78563 9.41729 5.49816 9.41737 5.19844C9.41744 4.89872 9.53657 4.6113 9.74855 4.39941C9.96054 4.18753 10.248 4.06853 10.5477 4.0686C10.8475 4.06867 11.1349 4.18781 11.3468 4.39979H11.3452Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2282_198">
                    <rect
                      width="18.0801"
                      height="18.0801"
                      fill="white"
                      transform="translate(0 0.419983)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>
              Customers will receive an individual QR code, via email, for each ticket purchased (or a single Qr code for a multi person ticket e.g. Family). The QR code will work either printed or on a mobile device.

              </span>
            </div>
            <div className="flex gap-2 items-center">
              <svg
                className="flex-shrink-0"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2282_198)">
                  <path
                    d="M11.3452 4.39979L15.6069 8.66142C15.8185 8.87329 15.9373 9.1605 15.9373 9.45995C15.9373 9.75941 15.8185 10.0466 15.6069 10.2585L11.346 14.5209C11.134 14.7329 10.8465 14.8519 10.5467 14.8519C10.2469 14.8519 9.95941 14.7329 9.74742 14.5209C9.53544 14.3089 9.41635 14.0214 9.41635 13.7216C9.41635 13.4218 9.53544 13.1343 9.74742 12.9223L12.0798 10.59H3.39001C3.09032 10.59 2.8029 10.4709 2.59098 10.259C2.37906 10.0471 2.26001 9.75965 2.26001 9.45995C2.26001 9.16026 2.37906 8.87284 2.59098 8.66092C2.8029 8.449 3.09032 8.32995 3.39001 8.32995H12.0798L9.74818 5.99762C9.53629 5.78563 9.41729 5.49816 9.41737 5.19844C9.41744 4.89872 9.53657 4.6113 9.74855 4.39941C9.96054 4.18753 10.248 4.06853 10.5477 4.0686C10.8475 4.06867 11.1349 4.18781 11.3468 4.39979H11.3452Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2282_198">
                    <rect
                      width="18.0801"
                      height="18.0801"
                      fill="white"
                      transform="translate(0 0.419983)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>
              When the customer arrives at your attraction, simply scan the customer's QR code ticket(s) via the DOWTK QR Scanner (link in your account when setup is completed). Or enter the ticket code directly into the redemption screen within your Ticket Manager account.
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <svg
                className="flex-shrink-0"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2282_198)">
                  <path
                    d="M11.3452 4.39979L15.6069 8.66142C15.8185 8.87329 15.9373 9.1605 15.9373 9.45995C15.9373 9.75941 15.8185 10.0466 15.6069 10.2585L11.346 14.5209C11.134 14.7329 10.8465 14.8519 10.5467 14.8519C10.2469 14.8519 9.95941 14.7329 9.74742 14.5209C9.53544 14.3089 9.41635 14.0214 9.41635 13.7216C9.41635 13.4218 9.53544 13.1343 9.74742 12.9223L12.0798 10.59H3.39001C3.09032 10.59 2.8029 10.4709 2.59098 10.259C2.37906 10.0471 2.26001 9.75965 2.26001 9.45995C2.26001 9.16026 2.37906 8.87284 2.59098 8.66092C2.8029 8.449 3.09032 8.32995 3.39001 8.32995H12.0798L9.74818 5.99762C9.53629 5.78563 9.41729 5.49816 9.41737 5.19844C9.41744 4.89872 9.53657 4.6113 9.74855 4.39941C9.96054 4.18753 10.248 4.06853 10.5477 4.0686C10.8475 4.06867 11.1349 4.18781 11.3468 4.39979H11.3452Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2282_198">
                    <rect
                      width="18.0801"
                      height="18.0801"
                      fill="white"
                      transform="translate(0 0.419983)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>
              When scanned / manually entered, we will show a confirm or review message. 'Confirm' is a valid ticket, 'Review' is a ticket that is not valid.
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <svg
                className="flex-shrink-0"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2282_198)">
                  <path
                    d="M11.3452 4.39979L15.6069 8.66142C15.8185 8.87329 15.9373 9.1605 15.9373 9.45995C15.9373 9.75941 15.8185 10.0466 15.6069 10.2585L11.346 14.5209C11.134 14.7329 10.8465 14.8519 10.5467 14.8519C10.2469 14.8519 9.95941 14.7329 9.74742 14.5209C9.53544 14.3089 9.41635 14.0214 9.41635 13.7216C9.41635 13.4218 9.53544 13.1343 9.74742 12.9223L12.0798 10.59H3.39001C3.09032 10.59 2.8029 10.4709 2.59098 10.259C2.37906 10.0471 2.26001 9.75965 2.26001 9.45995C2.26001 9.16026 2.37906 8.87284 2.59098 8.66092C2.8029 8.449 3.09032 8.32995 3.39001 8.32995H12.0798L9.74818 5.99762C9.53629 5.78563 9.41729 5.49816 9.41737 5.19844C9.41744 4.89872 9.53657 4.6113 9.74855 4.39941C9.96054 4.18753 10.248 4.06853 10.5477 4.0686C10.8475 4.06867 11.1349 4.18781 11.3468 4.39979H11.3452Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2282_198">
                    <rect
                      width="18.0801"
                      height="18.0801"
                      fill="white"
                      transform="translate(0 0.419983)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>
                You can check bookings and ticket sales within the{" "}
                <span className="font-bold">Ticket Manager Reports</span>{" "}
                section.
              </span>
            </div>
          </div>

          <div className="h-[1px] bg-[#D9D9D9] w-full mt-5"></div>
          <div className="flex items-center gap-2.5 justify-end w-full mt-5">
            <PrimaryButton
              scale="sm"
              type="button"
              className={`px-2`}
              onClick={onNext}
            >
              Continue to Terms & Conditions
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Container>
  );
}

function StepThree({
  onNext,
  data,
}: {
  onNext: () => void;
  data: AttractionDetails | undefined;
}) {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [terms, setTerms] = useState(false);
  const [sellPerm, setSellPerm] = useState(false);
  const [comission, setComission] = useState(false);
  const [the14DayPeriod, setThe14DayPeriod] = useState(false);
  const [publicPerm, setPublicPerm] = useState(false);

  const { control } = useFormContext();

  const nameWatcher = useWatch({
    control,
    name: "fullName",
  });
  const emailWatcher = useWatch({
    control,
    name: "email",
  });
  const emailSchema = z.string().email();
  const isEmailValid = emailSchema.safeParse(emailWatcher).success;

  // const allowedToContinue =
  //   terms &&
  //   sellPerm &&
  //   comission &&
  //   the14DayPeriod &&
  //   publicPerm &&
  //   nameWatcher &&
  //   emailWatcher &&
  //   isEmailValid;

  const allowedToContinue =
    terms && sellPerm && comission && the14DayPeriod && publicPerm;

  const [loading, setLoading] = useState(false);
  const [createSignatureRequest] = apiSlice.useCreateSignatureMutation();

  const createSignature = async () => {
    setLoading(true);
    try {
      const response = await createSignatureRequest({
        name: nameWatcher,
        email: emailWatcher,
        url: window.location.href,
      });

      if (!("data" in response)) {
        throw new Error(`HTTP error status: ${response.error}`);
      }

      const data = response.data;

      window.location.href = data.redirectUrl;
    } catch (error) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Error while starting the signature process.",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
      restartAnimation();
    }

    setLoading(false);
  };

  const scrollableNodeRef = createRef();
  return (
    <Container>
      <div className="w-full xl:pt-0">
        <div>
          <div className="font-bold text-sm text-[#212121]">
            Please read the full terms and conditions below and tick ALL
            checkboxes to confirm acceptance.
          </div>

          <div className="my-10 before:w-[calc(100%-56px)] before:h-full before:top-0 before:left-0 before:border before:border-[#787878] before:block before:absolute relative">
            <SimpleBar
              scrollableNodeProps={{ ref: scrollableNodeRef }}
              className="max-h-[560px]"
              autoHide={false}
            >
              <div className="py-4 pl-4 pr-[56px]">
                <TAndC />
              </div>
            </SimpleBar>
          </div>
          <div className="text-sm leading-[21px] font-normal flex flex-col gap-[15px]">
            <div className="mt-16 sm:mt-8 flex flex-col md:gap-16 sm:gap-20 gap-32">
              <label className="checkbox-item !w-full !md:w-[723px] primary-checkmark items-center flex">
                <span>
                  I have read the{" "}
                  <Link to="/terms-of-service" className="font-bold">
                    Terms and Conditions
                  </Link>
                  , and onboarding information provided. I am authorised by my
                  Attraction to set up, instruct and sell tickets via Day Out
                  With The Kids.
                </span>
                <input
                  className="!w-full !md:w-[723px] !h-5"
                  type="checkbox"
                  checked={terms}
                  onChange={(val) => {
                    setTerms(val.target.checked);
                  }}
                  readOnly
                />
                <span className="checkmark !border-black border"></span>
              </label>

              <label className="checkbox-item !w-full !md:w-[723px] primary-checkmark items-center flex">
                <span>
                  I understand and hereby give permission that Day Out with the
                  Kids can sell tickets through the Ticketing Manager platform.
                </span>
                <input
                  className="!w-full !md:w-[723px] !h-5"
                  type="checkbox"
                  checked={sellPerm}
                  onChange={(val) => {
                    setSellPerm(val.target.checked);
                  }}
                  readOnly
                />
                <span className="checkmark !border-black border"></span>
              </label>

              <label className="checkbox-item !w-full !md:w-[723px] primary-checkmark items-center flex">
                <span>
                  I understand that Day Out with Kids operates a
                  commission-based model. For every ticket sold, Day Out with
                  the Kids will deduct the agreed commission rate as dictated by
                  your attractions listing plan from each ticket transaction.
                </span>
                <input
                  className="!w-full !md:w-[723px] !h-5"
                  type="checkbox"
                  checked={comission}
                  onChange={(val) => {
                    setComission(val.target.checked);
                  }}
                  readOnly
                />
                <span className="checkmark !border-black border"></span>
              </label>

              <label className="checkbox-item !w-full !md:w-[723px] primary-checkmark items-center flex">
                <span>
                  I understand that Day Out with the Kids, will pay all monies
                  owed within 14 days of being invoiced by the attraction. Day
                  Out With The Kids will send a self-billing invoice to the
                  attraction within 7 days of of month end.
                </span>
                <input
                  className="!w-full !md:w-[723px] !h-5"
                  type="checkbox"
                  checked={the14DayPeriod}
                  onChange={(val) => {
                    setThe14DayPeriod(val.target.checked);
                  }}
                  readOnly
                />
                <span className="checkmark !border-black border"></span>
              </label>

              <label className="checkbox-item !w-full !md:w-[723px] primary-checkmark items-center flex">
                <span>
                  I confirm that I and my organisation are permitted to sell
                  tickets to the public.
                </span>
                <input
                  className="!w-full !md:w-[723px] !h-5"
                  type="checkbox"
                  checked={publicPerm}
                  onChange={(val) => {
                    setPublicPerm(val.target.checked);
                  }}
                  readOnly
                />
                <span className="checkmark !border-black border"></span>
              </label>
            </div>

            {/* <div className="mt-3">
              <div className="w-[306px]">
                <label htmlFor="signature" className="font-bold text-sm">
                  Authorised Person Signature
                </label>
                <textarea
                  name="signature"
                  id="signature"
                  className="w-full h-[87px] py-2.5 px-5 mt-2.5 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]"
                ></textarea>
              </div>
            </div> */}
            {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-20 sm:mt-0">
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"fullName"}
                label="Authorised Person Full Name"
                inputProps={{
                  ...control.register("fullName"),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"email"}
                label="Authorised Person Email Address"
                inputProps={{
                  ...control.register("email"),
                  type: "email",
                  autoComplete: "off",
                }}
              />
            </div> */}
          </div>

          <div className="h-[1px] bg-[#D9D9D9] w-full mt-5"></div>
          <div className="flex flex-col items-end gap-2 w-full mt-5">
            {/* <PrimaryButton
              scale="sm"
              type="button"
              className={`px-2`}
              onClick={createSignature}
              disabled={!allowedToContinue}
              loading={loading}
            >
              Continue to Signature
            </PrimaryButton> */}
            <PrimaryButton
              scale="sm"
              type="button"
              className={`px-2`}
              onClick={onNext}
              disabled={!allowedToContinue}
              loading={loading}
            >
              Proceed to Payment Details
            </PrimaryButton>
            {!isEmailValid && emailWatcher?.length > 0 && (
              <ErrorMessage>
                Please enter a valid email to continue.
              </ErrorMessage>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

function StepFour({
  isLoading,
  hasError,
}: {
  isLoading: boolean;
  hasError: boolean;
}) {
  const { control } = useFormContext();
  return (
    <Container>
      <div className="w-full">
        <div>
        <div className="font-extrabold text-base text-[#212121] mb-2">
        Payment Details
          </div>
          <div className="font-normal text-sm text-[#212121]">
            Please confirm the details where you would like payment for ticket
            sales paid. Day Out With The Kids will send a self-billing invoice
            to the attraction within 7 days of of month end. We will then make
            payment to the attraction within 14 days of invoice.
          </div>
          <div className="">
            <div className="grid grid-cols-1 gap-5 max-w-[437px] mt-5">
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"attractionName"}
                label="Attraction Name"
                readonly={true}
                inputProps={{
                  ...control.register("attractionName"),
                  autoComplete: "off",
                }}
              />

              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"businessName"}
                label="Business Name"
                inputProps={{
                  ...control.register("businessName"),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"businessAddress"}
                label="Business Address"
                inputProps={{
                  ...control.register("businessAddress"),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"businessPostcode"}
                label="Business Postcode"
                inputProps={{
                  ...control.register("businessPostcode", {
                    setValueAs: (value: string) => value.toUpperCase(),
                    onChange: (e) => {
                      e.target.value = e.target.value.toUpperCase();
                    },
                  }),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"vatNumber"}
                label="VAT Number (if applicable)"
                inputProps={{
                  ...control.register("vatNumber"),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"companyName"}
                label="Company Number (if applicable)"
                inputProps={{
                  ...control.register("companyName"),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"financeContactName"}
                label="Finance Contact Name"
                inputProps={{
                  ...control.register("financeContactName"),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"financeContactNumber"}
                label="Finance Contact Number"
                inputProps={{
                  ...control.register("financeContactNumber"),
                  autoComplete: "off",
                }}
              />
               <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"financeContactEmail"}
                label="Finance Contact Email"
                inputProps={{
                  ...control.register("financeContactEmail"),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"bankName"}
                label="Bank Name"
                inputProps={{
                  ...control.register("bankName"),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"bankAccountName"}
                label="Bank Account Name"
                inputProps={{
                  ...control.register("bankAccountName"),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"bankAccountSortCode"}
                label="Bank Account Sort Code"
                inputProps={{
                  ...control.register("bankAccountSortCode"),
                  autoComplete: "off",
                }}
              />
              <TextInput
                className="w-full"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="border text-cs-gray rounded-md py-3 px-2 flex flex-col w-full max-h-[40px] focus:outline-cs-pink"
                control={control}
                name={"bankAccountNumber"}
                label="Bank Account Number"
                inputProps={{
                  ...control.register("bankAccountNumber"),
                  autoComplete: "off",
                }}
              />
            </div>
          </div>

          <div className="h-[1px] bg-[#D9D9D9] w-full mt-5"></div>
          <div className="flex items-center gap-2.5 justify-end w-full mt-5">
            <PrimaryButton
              scale="sm"
              type="submit"
              className={`w-[155px]`}
              loading={isLoading}
            >
              Finish
            </PrimaryButton>
          </div>

          {hasError && (
            <ErrorMessage>
              Please fix the errors indicated above to continue
            </ErrorMessage>
          )}
        </div>
      </div>
    </Container>
  );
}

function StepCard({
  img,
  text,
  number,
}: {
  img: string;
  text: string;
  number: number;
}) {
  return (
    <div className="relative max-w-[252px]">
      <div className="mb-2.5 font-bold text-sm leading-4 flex items-center gap-2.5">
        <div className="flex-shrink-0 w-[26px] h-[26px] rounded-full border-2 border-[#979797] text-[#979797] font-fredoka text-sm font-normal flex justify-center items-center">
          {number}
        </div>
        <div>{text}</div>
      </div>
      <img src={img} alt={text} className="aspect-square w-full" />
    </div>
  );
}

export function StepZeroCard({
  title,
  desc,
  cta,
  className = "",
  disabled = true,
  onClick,
}: {
  title: string;
  desc: string;
  cta: string;
  className?: string;
  disabled?: boolean;
  onClick?: Function;
}) {
  return (
    <div
      className={`bg-white border border-[#D9D9D9] p-[15px] rounded-lg ${className}`}
    >
      <div className="font-extrabold text-[22px] leading-[26px]">{title}</div>
      <div className="font-normal text-sm leading-[21px] mt-2.5">{desc}</div>
      <button
        type="button"
        className={`${
          disabled ? "bg-[#979797] cursor-not-allowed" : "bg-cs-pink"
        } text-white rounded-lg h-[37px] px-2 flex justify-center items-center gap-0.5 mt-2.5 font-bold text-sm`}
        onClick={() => (!disabled ? onClick?.() : null)}
      >
        {cta}{" "}
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.49219 9.93555L8.00781 6.41992L4.49219 2.9043"
            stroke="white"
            strokeWidth="1.17188"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}

export function capFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}
