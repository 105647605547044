import Header from "../../components/global/amp/Header";
import Footer from "../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller, useWatch } from "react-hook-form";
import TextInput from "../../components/form/TextInput";
import { apiSlice } from "../../store/apiSlice";
import { globalActions } from "../../store/globalSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import type { AmpUserDetail } from "../../types/user";
import { useNavigate, useParams } from "react-router-dom";
import { SingleAttractionTemplate } from "../../components/global/SingleAttractionTemplate";
import { PrimaryButton } from "../../components/global/PrimaryButton";
import { ErrorMessage } from "../../components/global/ErrorMessage";
import { AttractionSearch } from "../../components/form/AttractionsSearch";
import { restartAnimation } from "../../components/global/amp/ToastNotification";
import { providers } from "./dashboard/AdminTicketingReporting";
import Select from "react-select";
import SingleSelectOption from "../../lib/react-select/SingleSelectOption";
import { DropDownIndicator } from "./attractions/AttractionOpeningTimes";
import BooleanSelect from "../../components/form/BooleanSelect";

const integrationOptions = [
  "with_attraction",
  "with_us",
  "with_provider",
  "completed",
] as const;
const integrationStatusOptions: {
  label: string,
  value: typeof integrationOptions[number]
}[] = [
  {
    label: 'With Attraction',
    value: 'with_attraction',

  },
  {
    label: 'With us',
    value: 'with_us',
  },
  {
    label: 'With provider',
    value: 'with_provider',
  },
  {
    label: 'completed',
    value: 'completed',
  }
]
const showOn = ["ATTRACTION_PAGE", "TICKET_PAGE", "BOTH"] as const;
const AddTicketMapping: React.FC<{ isEdit?: boolean }> = ({
  isEdit = false,
}) => {
  const { user, toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const {
    data: getQuery,
    isSuccess: isQuerySuccess,
    isFetching,
    refetch,
  } = apiSlice.useGetTicketMappingQuery(
    { id: id ?? "" },
    { skip: !isEdit || !id }
  );

  const [addTicketMapping, { isLoading, isError, isSuccess, error }] =
    apiSlice.useAddTicketMappingMutation();

  const schema = z.object({
    venue: z.string(),
    provider: z.enum(providers, {required_error: 'Required', invalid_type_error: 'Required'}),
    integration_status: z.enum(integrationOptions),
    notes: z.string(),
    status: z.boolean().nullable(),
    show_on: z.enum(showOn),
    display_name: z.string().nullable(),
    display_order: z.number().nullable(),
    commission: z.number({invalid_type_error: 'Invalid'}).min(1).max(100).nullable(),
    uses_calendar: z.boolean().nullable(),
    metadata: z.string(),
    pricing_options: z.string(),
    external_id: z.string().min(1, {message: 'Required'})
  });

  type AddTicketMappingForm = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    watch,
    control,
    getValues,
    setValue,
    reset,
  } = useForm<AddTicketMappingForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      status: true,
      uses_calendar: true,
      show_on: 'BOTH',
      notes: ""
    },
  });

  useEffect(() => {
    console.log(errors)
  }, [errors])
  useEffect(() => {
    document.title = `${isEdit ? 'Edit Mapping' : 'Add Mapping'} | Day Out With The Kids`;
    window.scrollTo(0, 0)
  }, [isEdit]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Successful",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot add changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !(user as AmpUserDetail)?.email_verified &&
      (user as AmpUserDetail).role !== "ADMIN"
    ) {
      navigate("/dashboard");
    }
  }, [navigate, user]);

  async function onSubmit() {
    const commission = getValues("commission");
    const payload: CreateAmpTicketMappingPayload = {
      id: isEdit ? id : undefined,
      venue_id: getValues("venue") ?? "",
      provider: getValues('provider'),
      integration_status: getValues("integration_status"),
      integration_note: getValues("notes"),
      is_active: getValues("status"),
      show_on: getValues("show_on"),
      display_name: getValues("display_name"),
      display_order: typeof getValues("display_order") === 'number' ? getValues('display_order') : null,
      commission: typeof commission === 'number' ? commission / 100 : null,
      uses_calendar: getValues("uses_calendar"),
      metadata: getValues("metadata") || "{}",
      pricing_options: getValues("pricing_options") || null,
    };

    if (!isEdit) {
      payload.external_id = getValues("external_id") ?? "";
    }

    const results = await addTicketMapping(payload);

    if ("data" in results && !isEdit) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Successful",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
      restartAnimation();
      return navigate(`/dashboard/ticket-mappings`, { replace: true });
    }

    await refetch();
    restartAnimation();
  }

  useEffect(() => {
    if (getQuery && isQuerySuccess && !isFetching) {
      reset(
        {
          venue: (getQuery.venue_id || "") as string,
          external_id: (getQuery.external_id || "") as string,
          provider: getQuery.provider as AddTicketMappingForm["provider"],
          integration_status: getQuery.integration_status as AddTicketMappingForm["integration_status"],
          notes: getQuery.integration_note || "",
          status: getQuery.is_active !== null ? !!getQuery.is_active : null,
          show_on: getQuery.show_on as AddTicketMappingForm["show_on"],
          display_name: getQuery.display_name || "",
          display_order: getQuery.display_order,
          commission:
            typeof getQuery.commission === "number"
              ? +(Number.parseFloat(getQuery.commission.toString()) * 100).toFixed(0)
              : null,
          uses_calendar:
            getQuery.uses_calendar !== null ? !!getQuery.uses_calendar : null,
            metadata: JSON.stringify(getQuery.metadata, null, 2),
            pricing_options: getQuery.pricing_options ? JSON.stringify(getQuery.pricing_options, null, 2) : "",
        },
        {
          keepDirty: false,
        }
      );
    }
  }, [getQuery, isFetching, isQuerySuccess, reset]);

  const defaultAttractions =
    getQuery?.venue && isEdit
      ? {
          label: getQuery?.venue?.name ?? "",
          value: (getQuery?.venue_id ?? "") as string,
          attraction: {
            id: (getQuery?.venue_id as string) ?? "",
            image: "",
            image_alt: "",
            location: "",
            title: getQuery?.venue?.name ?? "",
          },
          media: getQuery?.venue?.media,
        }
      : null;

  const providerOptions = providers.map((p) => ({
    value: p,
    label: p,
  }));

  const showOnOptions: {
    label: string;
    value: (typeof showOn)[number];
  }[] = [
    {
      label: "Attraction Page",
      value: "ATTRACTION_PAGE",
    },
    {
      label: "Ticket Page",
      value: "TICKET_PAGE",
    },
    {
      label: "Both",
      value: "BOTH",
    },
  ];

  const canEditReadonly = [
    'john@dayoutwiththekids.co.uk',
    'adam@dayoutwiththekids.co.uk'
  ].includes(user?.email || "");

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA]">
      <Header title={!isEdit ? "Add Mapping" : "Edit Mapping"} showBack />

      <SingleAttractionTemplate name={"Mapping"}>
        <form onSubmit={handleSubmit(onSubmit)} className="xl:mt-0 mt-[70px]">
          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 max-w-[570px] xl:w-3/5 gap-y-5">
            <div>
              <label className="text-black text-sm font-bold">Venue</label>
              <AttractionSearch
                value={watch("venue") ?? ""}
                defaultOptions={defaultAttractions}
                onChange={(value) => setValue("venue", value)}
              />
              {errors["venue"]?.message && (
                <ErrorMessage>{errors["venue"]?.message}</ErrorMessage>
              )}
            </div>

            <div className="w-full inline-flex flex-col flex-wrap">
              <label className="text-black text-sm font-bold">Provider</label>
              <Controller
                control={control}
                name={"provider"}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <Select
                    value={providerOptions.find((o) => o.value === value)}
                    components={{
                      Option: SingleSelectOption,
                      DropdownIndicator: DropDownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    options={providerOptions}
                    isSearchable={false}
                    styles={{
                      menu: (styles) => ({
                        ...styles,
                        margin: 0,
                        border: "none",
                      }),
                      menuList: (base, isSelected) => ({
                        ...base,
                        paddingTop: 0,
                        paddingBottom: 0,
                        background: "white",

                        "::-webkit-scrollbar": {
                          width: 14,
                        },
                        "::-webkit-scrollbar-thumb": {
                          background: "#c2c2c2",
                          borderRadius: 10,
                          border: "4px solid white",
                        },
                      }),
                      option: (
                        base,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...base,
                          background: "white",
                          border: "1px solid #c2c2c2",
                          color: isSelected ? "white" : "#555",
                          "&:hover": {
                            backgroundColor: "#6836D1",
                            color: isSelected || isFocused ? "white" : "#555",
                            border: isFocused ? "1px solid white" : "",
                          },
                          "&:active": {
                            backgroundColor: "#6836D1",
                            color: "white",
                          },
                        };
                      },
                      singleValue: (base) => ({
                        ...base,
                        color: "#555",
                        fontSize: 14,
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        cursor: "pointer",
                        outline: "none",
                        border: state.isFocused
                          ? "1px solid #CFDBD5"
                          : "1px solid #CFDBD5",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #CFDBD5"
                            : "1px solid #CFDBD5",
                        },
                        boxShadow: "none",
                        paddingTop: 3,
                        paddingBottom: 3,
                        margin: 0,
                        minHeight: 40,
                        borderRadius: 8,
                      }),
                      valueContainer: (styles) => ({
                        ...styles,
                        paddingRight: 20,
                        paddingLeft: 20,
                        fontSize: 14,
                      }),
                    }}
                    onChange={(value) => onChange(value?.value)}
                  />
                )}
              />

              {errors["provider"] && (
                <ErrorMessage>{errors["provider"]["message"]}</ErrorMessage>
              )}
            </div>

            <div className="w-full inline-flex flex-col flex-wrap">
              <label className="text-black text-sm font-bold">
                Integration Status
              </label>
              <Controller
                control={control}
                name={"integration_status"}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <Select
                    value={integrationStatusOptions.find(
                      (o) => o.value === value
                    )}
                    components={{
                      Option: SingleSelectOption,
                      DropdownIndicator: DropDownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    options={integrationStatusOptions}
                    isSearchable={false}
                    styles={{
                      menu: (styles) => ({
                        ...styles,
                        margin: 0,
                        border: "none",
                      }),
                      menuList: (base, isSelected) => ({
                        ...base,
                        paddingTop: 0,
                        paddingBottom: 0,
                        background: "white",

                        "::-webkit-scrollbar": {
                          width: 14,
                        },
                        "::-webkit-scrollbar-thumb": {
                          background: "#c2c2c2",
                          borderRadius: 10,
                          border: "4px solid white",
                        },
                      }),
                      option: (
                        base,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...base,
                          background: "white",
                          border: "1px solid #c2c2c2",
                          color: isSelected ? "white" : "#555",
                          "&:hover": {
                            backgroundColor: "#6836D1",
                            color: isSelected || isFocused ? "white" : "#555",
                            border: isFocused ? "1px solid white" : "",
                          },
                          "&:active": {
                            backgroundColor: "#6836D1",
                            color: "white",
                          },
                        };
                      },
                      singleValue: (base) => ({
                        ...base,
                        color: "#555",
                        fontSize: 14,
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        cursor: "pointer",
                        outline: "none",
                        border: state.isFocused
                          ? "1px solid #CFDBD5"
                          : "1px solid #CFDBD5",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #CFDBD5"
                            : "1px solid #CFDBD5",
                        },
                        boxShadow: "none",
                        paddingTop: 3,
                        paddingBottom: 3,
                        margin: 0,
                        minHeight: 40,
                        borderRadius: 8,
                      }),
                      valueContainer: (styles) => ({
                        ...styles,
                        paddingRight: 20,
                        paddingLeft: 20,
                        fontSize: 14,
                      }),
                    }}
                    onChange={(value) => onChange(value?.value)}
                  />
                )}
              />

              {errors["integration_status"] && (
                <ErrorMessage>
                  {errors["integration_status"]["message"]}
                </ErrorMessage>
              )}
            </div>

            <div className={`flex flex-col w-full md:w-3/4 xl:w-full`}>
              <span className="flex items-center font-bold text-sm">Note</span>
              <div className="relative flex flex-col gap-2.5">
                <Controller
                  control={control}
                  name={"notes"}
                  render={({ field: { ref, value, onChange } }) => (
                    <>
                      <textarea
                        value={value ?? ""}
                        onChange={onChange}
                        placeholder=""
                        className="text-cs-gray placeholder:text-sm text-md p-3 w-full mt-2 border-[#CFDBD5] rounded-lg border-cs-1 outline-cs-pink h-40"
                      ></textarea>
                    </>
                  )}
                />
              </div>

              {errors["notes"] && (
                <ErrorMessage>{errors["notes"].message}</ErrorMessage>
              )}
            </div>

            <div className="w-full inline-flex flex-col flex-wrap">
              <label className="text-black text-sm font-bold">Status</label>
              <Controller
                control={control}
                name="status"
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <BooleanSelect
                    value={value}
                    onChange={onChange}
                    options={[
                      {
                        label: "Active",
                        value: "yes",
                      },
                      {
                        label: "Inactive",
                        value: "no",
                      },
                    ]}
                  />
                )}
              />
              {errors["status"] && (
                <ErrorMessage>{errors["status"]["message"]}</ErrorMessage>
              )}
            </div>

            <div className="w-full inline-flex flex-col flex-wrap">
              <label className="text-black text-sm font-bold">Show On</label>
              <Controller
                control={control}
                name={"show_on"}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <Select
                    value={showOnOptions.find((o) => o.value === value)}
                    components={{
                      Option: SingleSelectOption,
                      DropdownIndicator: DropDownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    options={showOnOptions}
                    isSearchable={false}
                    styles={{
                      menu: (styles) => ({
                        ...styles,
                        margin: 0,
                        border: "none",
                      }),
                      menuList: (base, isSelected) => ({
                        ...base,
                        paddingTop: 0,
                        paddingBottom: 0,
                        background: "white",

                        "::-webkit-scrollbar": {
                          width: 14,
                        },
                        "::-webkit-scrollbar-thumb": {
                          background: "#c2c2c2",
                          borderRadius: 10,
                          border: "4px solid white",
                        },
                      }),
                      option: (
                        base,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...base,
                          background: "white",
                          border: "1px solid #c2c2c2",
                          color: isSelected ? "white" : "#555",
                          "&:hover": {
                            backgroundColor: "#6836D1",
                            color: isSelected || isFocused ? "white" : "#555",
                            border: isFocused ? "1px solid white" : "",
                          },
                          "&:active": {
                            backgroundColor: "#6836D1",
                            color: "white",
                          },
                        };
                      },
                      singleValue: (base) => ({
                        ...base,
                        color: "#555",
                        fontSize: 14,
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        cursor: "pointer",
                        outline: "none",
                        border: state.isFocused
                          ? "1px solid #CFDBD5"
                          : "1px solid #CFDBD5",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #CFDBD5"
                            : "1px solid #CFDBD5",
                        },
                        boxShadow: "none",
                        paddingTop: 3,
                        paddingBottom: 3,
                        margin: 0,
                        minHeight: 40,
                        borderRadius: 8,
                      }),
                      valueContainer: (styles) => ({
                        ...styles,
                        paddingRight: 20,
                        paddingLeft: 20,
                        fontSize: 14,
                      }),
                    }}
                    onChange={(value) => onChange(value?.value)}
                  />
                )}
              />

              {errors["show_on"] && (
                <ErrorMessage>{errors["show_on"]["message"]}</ErrorMessage>
              )}
            </div>

            {isEdit && (
              <TextInput
                name={"name"}
                label="External Name"
                className="w-full mt-3 group"
                labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                control={control}
                inputProps={{
                  value: getQuery?.name || "",
                }}
                readonly={true}
              />
            )}

            <TextInput
              name={"display_name"}
              label="Display Name"
              inputProps={{ ...register("display_name") }}
              requirements="Only use to override default name"
              className="w-full mt-3 group"
              labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
              inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
            />

            <TextInput
              name={"display_order"}
              label="Display Order"
              inputProps={{
                ...register("display_order", { setValueAs(value) {
                  if (!value) {
                    return null
                  }
                  return parseInt(value)
                }, }),
                type: "number",
              }}
              requirements="Use to override the default order of tickets of an attraction with 0 being the first in order."
              className="w-full mt-3 group"
              labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
              inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
            />

            <TextInput
              name={"commission"}
              label="Commission Percentage"
              inputProps={{
                ...register("commission", {
                  valueAsNumber: true,
                  min: 0,
                  max: 100,
                }),
                type: "number",
              }}
              requirements=""
              className="w-full mt-3 group"
              labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
              inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
            />

            <div className="w-full inline-flex flex-col flex-wrap">
              <label className="text-black text-sm font-bold">
                Uses Calendar
              </label>
              <Controller
                control={control}
                name="uses_calendar"
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <BooleanSelect value={value} onChange={onChange} />
                    {(value === true || value === false) && (
                      <TextInput
                        name={"external_id"}
                        label="External ID"
                        className="w-full mt-3 group"
                        labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                        inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                        control={control}
                        inputProps={{
                          ...register('external_id')
                        }}
                        readonly={isEdit}
                      />
                    )}
                    {value === true && (
                      <div className="my-2">
                        <label className="text-black text-sm font-bold">
                          Configuration
                        </label>
                        <textarea
                          {...register('metadata')}
                          readOnly={!canEditReadonly}
                          placeholder=""
                          className={`text-cs-gray placeholder:text-sm text-md p-3 w-full mt-2 border-[#CFDBD5] rounded-lg border-cs-1 outline-cs-pink h-40 ${!canEditReadonly ? 'bg-[#f2f2f2]' : ''}`}
                        ></textarea>
                        <p className="text-xs font-normal text-cs-gray">
                          Don't edit
                        </p>
                      </div>
                    )}

                    {value === true && (
                      <div className="my-2">
                        <label className="text-black text-sm font-bold">
                          Pricing Options
                        </label>
                        <textarea
                          {...register('pricing_options')}
                          readOnly={!canEditReadonly}
                          placeholder=""
                          className={`text-cs-gray placeholder:text-sm text-md p-3 w-full mt-2 border-[#CFDBD5] rounded-lg border-cs-1 outline-cs-pink h-40 ${!canEditReadonly ? 'bg-[#f2f2f2]' : ''}`}
                        ></textarea>
                        <p className="text-xs font-normal text-cs-gray">
                          Don't edit
                        </p>
                      </div>
                    )}
                  </>
                )}
              />
              {errors["uses_calendar"] && (
                <ErrorMessage>
                  {errors["uses_calendar"]["message"]}
                </ErrorMessage>
              )}
            </div>
          </fieldset>

          <PrimaryButton type="submit" loading={isLoading} scale="sm">
            {isLoading ? (
              <>
                <span className="flex justify-center">
                  <svg
                    className="w-5 h-5 mr-3 animate-spin text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Saving...
                </span>
              </>
            ) : !isEdit ? (
              "Add Mapping"
            ) : (
              "Save Changes"
            )}
          </PrimaryButton>
          {isSubmitted && !isValid && (
            <ErrorMessage>
              You have some errors! Scroll up to view & fix
            </ErrorMessage>
          )}

          {error && "data" in error && (
            <ErrorMessage>
              {error?.data?.message?.replaceAll('"', "") ?? ""}
            </ErrorMessage>
          )}
        </form>
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AddTicketMapping;
